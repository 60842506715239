import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from "./auth-header";

export const AddFaqSiteApi = async (site_name) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/add_faq_site"), {
        site_name
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

export const AddFaqGroupApi = async (group_name, site_id, priority = 0) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/add_faq_group"), {
          group_name, site_id, priority
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const AddFaqItemApi = async (title, answer, is_live, group_id = -1, site_id = -1, priority = 0) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/add_faq_item"), {
          title, answer, is_live, group_id, site_id, priority
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const ChangeFaqSiteNameApi = async (site_id, site_name) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/change_site_name"), {
          site_id, site_name
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const ChangeFaqGroupApi = async (group_id, group_name, priority) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/change_group_name"), {
          group_id, group_name, priority
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const UpdateFaqItemApi = async (item_id, title, answer, is_live, group_id, priority = 0) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/update_faq_item"), {
        item_id, title, answer, is_live, group_id, priority
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const DeleteFaqSiteApi = async (faq_site_id) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/delete_site"), {
          faq_site_id
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const DeleteFaqGroupApi = async (faq_group_id) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/delete_group"), {
          faq_group_id
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const DeleteFaqItemApi = async (faq_item_id) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/delete_item"), {
          faq_item_id
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const GetFaqGroups = async (site_name) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await GetRequest(GenerateUrl("/faq_groups"), {
          site_name
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const GetFaqItemsSite = async (site_name) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await GetRequest(GenerateUrl("/faq_items_site"), {
          site_name
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const GetFaqItemsGroup = async (faq_group_id) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await GetRequest(GenerateUrl("/faq_items_group"), {
          faq_group_id
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

function dummyPlans() {
  return {
    data: "ok",
    success: true,
    errors: []
  }
}
