import { Button, DatePicker, Form, Input, message, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import GetDetailedIgUserObject from '../../Common/ApiCall/GrowpadV2ApiCalls/GetDetailedIgUserObject'
import { BadNotif } from '../../Common/Utils/SendNotification';
import UpdateIgExpiryTime from '../../Common/ApiCall/GrowpadV2ApiCalls/UpdateIgExpiryTime'
import moment from 'moment'
import { useForm } from 'antd/lib/form/Form';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function date(date_1) {
  const dateFormat = 'YYYY/MM/DD';
  let date = new Date(date_1)
  return moment(date, dateFormat)
}

const IgUserPanel = ({userIgId, visitUser}) => {
  const [user, setUser] = useState(null)
  const [load, setLoad] = useState(true)
  const [form] = useForm()

  function refreshData() {getDetailedIgUser(userIgId)}

  function getDetailedIgUser(user_id) {
    setLoad(true)
    GetDetailedIgUserObject(user_id)
      .then(r => {
        if(r.success) {
          setUser(r.data)
        } else {
          BadNotif('', r)
        }
        setLoad(false)
      })
  }

  useEffect(()=>{
    refreshData()
  }, [])

/*   useEffect(()=>{
    console.log(form.isFieldTouched('trial_expiry_time'))
  }) */

  function changeExpiry() {
    let t = form.getFieldValue('trial_expiry_time').format('YYYY-MM-DD HH:mm:ss')
    setLoad(true)
    UpdateIgExpiryTime(userIgId, t)
      .then(r => {
        if(r.success) {
          message.success("Expiry Time Changed")
        } else {
          BadNotif('', r)
        }
        setLoad(false)
        refreshData()
      })
  }

  return(
    <div>
      {
        load ? (
          <Skeleton 
            active
          />
        ) : (
          <Form
            {...layout}
            form={form}
            initialValues={user && {
              username: user.username,
              created_at: user.created_at,
              trial_expiry_time: date(user.trial_expiry_time)
            }}
          >
            <Form.Item
              label="Username"
              name="username"
            >
              <Input 
                disabled
              />              
            </Form.Item>
            <Form.Item
              name="trial_expiry_time"
              label="Expiry Time"
            >
              <DatePicker 
                
              />
            </Form.Item>
            <Form.Item 
              {...tailLayout}
              shouldUpdate
            >
              {()=> {
                return (
                  form.isFieldTouched('trial_expiry_time') ? (
                    <Button type="dashed" onClick={changeExpiry}>
                      Save
                    </Button>
                  ) : null
                )
              }}
            </Form.Item>
            <Form.Item
              label="Created At"
              name="created_at"
            >
              <Input 
                disabled
              />
            </Form.Item>
            <Form.Item
              {...tailLayout}
            >
              {user && user.user_exists ? (
                <Button type="primary" onClick={()=>visitUser(user.user_exists)}>
                  View/Edit User
                </Button>
              ) : null}
            </Form.Item>
          </Form>
        )
      }
    </div>
  )
}

export default IgUserPanel