import ApiError from "../../Models/ApiError";

export const RunServer = true

export const ProfilebudAgencyURL = "https://api3.profilebud.com/api/admin/v1"

export const DevUrl = "http://localhost:3000/api/admin/v1/"

export const AgencyPassword = "some_random_password_3788sjkkdfsdf"

export function GenerateAgencyUrl(path: string) {
  return ProfilebudAgencyURL + path
}

let message = "Something went wrong. Error: "

export function ConstructErrorMessage(ApiMessage: string) {
  return new ApiError(message+ApiMessage)
}