import React, { useState , useEffect} from 'react'
import {Button, Input, Space, Table, Form, Typography, Switch, Spin} from 'antd'
import GetTrainingItems from '../../Common/ApiCall/GetTrainingItems'
import ChangeTrainingTitle from '../../Common/ApiCall/ChangeTrainingTitle';
import ChangeTrainingLink from '../../Common/ApiCall/ChangeTrainingLink'
import ChangeTrainingLive from '../../Common/ApiCall/ChangeTrainingLive'
import ChangeTrainingBlog from '../../Common/ApiCall/ChangeTrainingBlog'
import AddTrainingItem, {DeleteTrainingItem} from '../../Common/ApiCall/AddTrainingItem'
import ChangeTrainingExcerptApi from '../../Common/ApiCall/ChangeTrainingExcerptApi'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
const tailLayout = {
    wrapperCol: {offset: 6, span: 14}
}

const {Title} = Typography
const {TextArea} = Input

const AllTrainingItems = () => {
    const [items, setItems] = useState([])
    const [editor, setEditor] = useState(false)
    const [add, setAdd] = useState(false)
    const [trainingItemId, setTrainingItemId] = useState(-1)
    const [load, setLoad] = useState(false)
    const [trainingItem, setTrainingItem] = useState({})

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Live',
            dataIndex: 'is_live',
            key: 'is_live',
            render : text => <p>{text ? "Yes" : "No"}</p>
        },
        {
            title: 'Blog/Video',
            dataIndex: 'is_blog',
            key: 'is_blog',
            render: text => <p>{text ? "Blog" : "Video"}</p>
        },
        {
            title: 'Action',
            key: 'action',
            render: (item) => (
                <Space size="middle">
                    <Button onClick={()=>{setEditor(true);setTrainingItemId(item.id);setTrainingItem(item)}} >
                        View / Edit
                    </Button>
                    <Button type="danger" onClick={()=>{remove(item.id)}} >
                        Delete
                    </Button>
                </Space>
            )
        }
    ]

    useEffect(() => {
        GetTrainingItems()
            .then(r => {
                setItems(r.data)
            })
    }, [])

    async function RefreshTrainingData() {
        GetTrainingItems()
            .then(r => {
                setItems(r.data)
                for(let i=0;i<r.data.length;i++) {
                    if(r.data[i].id === trainingItemId) {
                        setTrainingItem(r.data[i])
                        break
                    }
                }
            })
    }

    function remove(id) {
        setLoad(true)
        DeleteTrainingItem(id)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Item Removed')
                    RefreshTrainingData()
                } else {
                    BadNotif('', r)
                }
                setLoad(false)
            })
    }

    if(editor) {
        return(
            <>
            <Title level={2}>
                Training Item 
                <Button className="menu-float-right" onClick={()=>setEditor(false)} >
                  Back to All
                </Button>
            </Title>
                <TrainingItemEditor 
                    refresh={RefreshTrainingData}
                    item={trainingItem}
                />
            </>
        )
    }

    if(add) {
        return(
            <>
                <Title level={2}>
                    Add Training Item 
                    <Button className="menu-float-right" onClick={()=>setAdd(false)} >
                    Back to All
                    </Button>
                </Title>
                <AddTrainingItemForm 
                    refresh={RefreshTrainingData}
                    back={()=>setAdd(false)}
                />
            </>
        )
    }

    return(
        <>
            {load ? <Spin /> : null}
            <Button 
                onClick={()=>setAdd(true)} 
                style={{
                    marginBottom: '20px'
                }}
            >
                Add New Training Item
            </Button>
            <Table 
                dataSource={items}
                columns={columns}
            />
        </>
    )
}

const TrainingItemEditor = ({item, refresh}) => {
    const [titleForm] = Form.useForm()
    const [linkForm] = Form.useForm()
    const [liveForm] = Form.useForm()
    const [blogForm] = Form.useForm()
    const [excerptForm] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [, forceUpdate] = useState()

    useEffect(() => {
        forceUpdate({});
      }, []);

    function updated() {
        refresh()
    }

    function changeTrainingTitle(values) {
        setLoading(true)
        ChangeTrainingTitle(item.id, values.title)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                } else {
                    BadNotif(r)
                }
                updated()
                setLoading(false)
            })
    }

    function changeTrainingLink(values) {
        setLoading(true)
        ChangeTrainingLink(item.id, values.link)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                } else {
                    BadNotif(r)
                }
                updated()
                setLoading(false)
            })
    }
    
    function changeTrainingIsLive(values) {
        setLoading(true)
        ChangeTrainingLive(item.id, values.is_live)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                } else {
                    BadNotif(r)
                }
                updated()
                setLoading(false)
            })
    }

    function changeTrainingIsBlog(values) {
        setLoading(true)
        ChangeTrainingBlog(item.id, values.is_blog)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                } else {
                    BadNotif(r)
                }
                updated()
                setLoading(false)
            })
    }

    function changeTrainingExcerpt(values) {
        setLoading(true)
        ChangeTrainingExcerptApi(item.id, values.excerpt)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                } else {
                    BadNotif(r)
                }
                updated()
                setLoading(false)
            })
    }

    return(
        <>
        {
            loading ? (
                <Spin />
            ) : null
        }
        <Form
            {...formItemLayout}
            form={titleForm}
            onFinish={changeTrainingTitle}
            initialValues={{
                title: item.title
            }}
        >
            <Form.Item
                label="Title"
                name="title"
            >
                <Input 
                    placeholder="Title"
                />
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!titleForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        <Form
            {...formItemLayout}
            form={linkForm}
            onFinish={changeTrainingLink}
            initialValues={{
                link: item.link
            }}
        >
            <Form.Item
                    label="Link"
                    name="link"
                >
                <Input 
                    placeholder="link"
                />
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!linkForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        <Form
            {...formItemLayout}
            form={liveForm}
            onValuesChange={changeTrainingIsLive}
            initialValues={{
                is_live: item.is_live
            }}
        >
            <Form.Item
                label="Live"
                name="is_live"
                valuePropName="checked"
            >
                <Switch 
                    checked={item.is_live}
                />
            </Form.Item>
        </Form>
        <Form
            {...formItemLayout}
            form={blogForm}
            onValuesChange={changeTrainingIsBlog}
            initialValues={{is_blog: item.is_blog}}
        >
            <Form.Item
                label="Video / Blog"
            >
                Video&nbsp;
                <Form.Item
                    name="is_blog"
                    style={{display: 'inline-block'}}
                    valuePropName="checked"
                >
                    <Switch 
                        checked={item.is_blog}
                    />
                </Form.Item>
                &nbsp;Blog
            </Form.Item>
        </Form>
        <Form
            {...formItemLayout}
            form={excerptForm}
            onFinish={changeTrainingExcerpt}
            initialValues={{excerpt: item.excerpt}}
        >
            <Form.Item
                label="Excerpt"
                name="excerpt"
            >
                <TextArea />
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!excerptForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        </>
    )
}

const AddTrainingItemForm = ({back, refresh}) => {
    const [loading, setLoading]= useState(false)

    function submit(values) {
        setLoading(true)
        AddTrainingItem(values.title, values.is_live, values.is_blog, values.link, values.excerpt)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Added Successfully.')
                    refresh()
                    back()
                } else {
                    BadNotif('', r)
                }
                setLoading(false)
            })
    }

    return(
        <>
        {
            loading ? (
                <Spin />
            ) : null
        }
        <Form
            {...formItemLayout}
            onFinish={submit}
            hideRequiredMark
            initialValues={{
                title: '',
                link: '',
                excerpt: '',
                is_blog: false,
                is_live: false
            }}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{required: true}]}
            >
                <Input 
                    placeholder="Title"
                />
            </Form.Item>
            <Form.Item
                    label="Link"
                    name="link"
                    rules={[{required: true}]}
                >
                <Input 
                    placeholder="link"
                />
            </Form.Item>
            <Form.Item
                label="Excerpt"
                name="excerpt"
            >
                <TextArea />
            </Form.Item>
            <Form.Item
                label="Live"
                name="is_live"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Video / Blog"
            >
                Video&nbsp;
                <Form.Item
                    style={{display: 'inline-block'}}
                    name="is_blog"
                >
                    <Switch />
                </Form.Item>
                &nbsp;Blog
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        </>
    )
}

export default AllTrainingItems