import React, {useState} from "react";
import {Checkbox, Form, Input, Button, Spin, DatePicker} from "antd";
import {GrowpadAddUserApi} from "../../Common/ApiCall/GrowpadApi";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import moment from 'moment';
import ApiError from '../../Common/Models/ApiError'
import SendNotification from '../../Common/Utils/SendNotification'
import NotificationTypeEnum from '../../Common/Models/NotificationTypeEnum'
import GetIgDataFromUsername from "../../Common/ApiCall/GetIgDataFromUsername";
import IgAccountCard from './IgAccountCard'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const dateFormat = 'YYYY/MM/DD';
let date = new Date()
date.setDate(date.getDate() + 30)
const d = moment(date, dateFormat)

const AddUser = () => {
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [igAccount, setIgAccount] = useState(null)

  const onFinish = values => {
    setLoad(true)
    if(igAccount === null) {
      setLoad(false)
      BadNotif("Add Ig Account to Continue", null)
      return
    }
    GrowpadAddUserApi(values.email, values.password, values.has_base_product, values.expiry_time, igAccount.id, igAccount.username)
      .then(r => {
        if(r.success) {
          SuccessNotif("Added Successfully")
        } else {
          BadNotif("", r)
        }
        setLoad(false)
      })
  };

  async function makeDetailedIDFromUsernames() {
    setLoading(true)
    if(username === '') {
      SendNotification(NotificationTypeEnum.Failure, "Enter Username to Continue")
      setLoading(false)
      return
    }
    let userData = await GetIgDataFromUsername(username)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      }
      setLoading(false)
      return
    }
    if(!userData.id) {
      SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      setLoading(false)
      return
    }
    setIgAccount(userData)
    setLoading(false)
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return(
    <div>
      <Form
        {...layout}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        hideRequiredMark
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{required: true, message: 'Please input Email'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{required: true, message: 'Please input Password'}]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          rules={[{required: true}]}
          name="expiry_time"
          label="Expiry Date"
          initialValue={d}
        >
          <DatePicker 
          />
        </Form.Item>
        <Form.Item
          label="Ig Account"
        >
          {
            igAccount && igAccount.id ? (
              <IgAccountCard 
              ig_user={igAccount}
              igAccounts={igAccount}
              ig_id={igAccount.id}
              ig_username={igAccount.username}
              ig_profile_url={igAccount.avatar}
              remove={()=>setIgAccount(null)}
          />
            ) : (
              <>
              <Input 
                onChange={(c)=>setUsername(c.target.value)}
                placeholder="Enter Ig Username"
              />
                  <Button disabled={username.length===0} onClick={makeDetailedIDFromUsernames} >
                    Confirm                
                  </Button>
              </>
            )
          }
        </Form.Item>
        <Form.Item
          {...tailLayout}
          name="has_base_product"
          initialValue={true}
        >
          <Checkbox>Premium</Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout} >
          {load ? (
            <Button type="primary" >
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>

      </Form>
    </div>
  )
}

export default AddUser