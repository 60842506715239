import { GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { AgencyPassword, GenerateAgencyUrl, RunServer } from "./ApiUrl";

const GetDetailedUser = async (user_id: string) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateAgencyUrl("/detailed_user"), {
      user_id,
      pwd: AgencyPassword
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {}
}

export default GetDetailedUser
