import React, {useState, useEffect} from "react";
import {AutoComplete, Form, Typography, Input} from "antd";
import { GrowpadGetDetailedUserIgId ,GrowpadQueryEmailApi, GrowpadGetDetailedUserByIgUsername } from "../../Common/ApiCall/GrowpadApi";
import SendNotification, {BadNotif} from '../../Common/Utils/SendNotification'
import NotificationTypeEnum from '../../Common/Models/NotificationTypeEnum'

const {Option} = AutoComplete
const {Title} = Typography
const {Search} = Input

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SearchUserEmail = ({setUId, setIg, setUser}) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const onSearch = (text) => {
    if(text.length < 1) {return}
    GrowpadQueryEmailApi(text)
    .then(r => {
      if(r.success) {
        setOptions(r.data)
      } else {
        SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
      }
    })
  }

  const onIgIdSearch = (text) => {
    if(text.length < 1) {
      SendNotification('Enter Ig ID')
    }
    GrowpadGetDetailedUserIgId(text)
      .then(r => {
        if(r.success) {
          goToUserPanel(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)          
        }
      })
  }

  const onIgUsernameSearch = (text) => {
    if(text.length < 1) {
      SendNotification('Enter Ig Username')
    }
    GrowpadGetDetailedUserByIgUsername(text)
      .then(r => {
        if(r.success) {
          goToUserPanel(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)          
        }
      })
  }

  const onSelect = (data) => {
    goToUserPanel(data)
  };
  const onChange = (data) => {
    setValue(data);
  };

  const goToUserPanel = (user_id) => {
    setUId(user_id)
    setUser()
  }

  // DEV
  /*
  useEffect(()=>{
    onSelect('2000.aman.sinha@gmail.com')
  }, [])
  */

  /*
  useEffect(()=>{
    onIgUsernameSearch('_a_gryffindor')
  }, [])
  */

  return(
    <>

    <Form
        {...layout}
      >
          <Form.Item
            label="Email: "
            rules={[{required: true}]}
          >
          <AutoComplete
            value={value}
            style={{width: 200}}
            placeholder="Enter Email to Continue"
            onSelect={onSelect}
            onSearch={onSearch}
            onChange={onChange}
          >
            {options.map((item)=>(
              <Option key={item.id} value={item.id} title={item.payment_email} >
                {item.payment_email}
              </Option>
            ))}
          </AutoComplete>
          </Form.Item>
      </Form>

      <Form
        {...layout}
      >
          <Form.Item
            label="IG USERNAME: "
            rules={[{required: true}]}
          >
            <Search 
              onPressEnter={e=>onIgUsernameSearch(e.target.value)}
              onSearch={onIgUsernameSearch}
              enterButton="Search"
              placeholder="Enter IG Username Here"
            />
          </Form.Item>
      </Form>

      <Form
        {...layout}
      >
          <Form.Item
            label="IG ID: "
            rules={[{required: true}]}
          >
            <Search 
              onPressEnter={e=>onIgIdSearch(e.target.value)}
              onSearch={onIgIdSearch}
              enterButton="Search"
              placeholder="Enter IG ID Here"
            />
          </Form.Item>
      </Form>

    </>
  )
}

export default SearchUserEmail