import { Button, Typography, Spin, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Loader from '../../Components/Loader'
import ShowAccounts from './ShowAccounts'
import SearchBox from './SearchBox'

const {Title} = Typography

const AddIgAccounts = ({
  accounts, 
  nextPage, 
  maxAccounts, 
  removeIg, 
  setAccounts, 
  username,
  addIg,
  newAccounts,
  setNewAccounts
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    console.log(accounts, maxAccounts)
  }, [])

  var removeByAttr = function(arr, attr, value){
    var i = arr.length;
    let arr2 = arr.slice()
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(attr) 
           && (arguments.length > 2 && arr[i][attr] === value ) ){ 
           arr2.splice(i,1);
       }
    }
    setNewAccounts(arr2)
  }

  const removeAccount = (id, newIg) => {
    newIg ? removeByAttr(newAccounts, 'id', id) : removeIg(id)
  } 

  return(
    <div>
      <div >
        {console.log(accounts)}
        <div>
          {loading ? (
            <Loader />
          ) : (
            <SearchBox
              username={username}
              maxAccounts={maxAccounts}
              accounts={accounts}
              setigAccounts={addIg}
            />
          )}

          <ShowAccounts 
            accounts={accounts}
            newAccounts={newAccounts}
            removeAccount={removeAccount}
          />

{/*           {
            maxAccounts - accounts.length ? (
              <p className="block">
                Add {maxAccounts - accounts.length} Instagram {maxAccounts-accounts.length>1 ? "Accounts" : "Account"}
                <div className="has-text-centered">
                  <Button 
                    size="large"
                    type="primary"
                    disabled={accounts.length===0}
                    onClick={()=>nextPage(accounts)}
                  >git
                    Save
                  </Button>
                </div>
              </p>
            ) : null
          } */}
        </div>
      </div>
    </div>
  )
}

export default AddIgAccounts