import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Card } from 'antd'

const {Meta} = Card

const ShowAccounts = ({
  accounts, 
  removeAccount,
  newAccounts
}) => {

  return(
    <div className="columns is-multiline">
      {accounts.map((account, index) => (
        <IgAccountCard 
          username={account.username}
          avatar={account.profile_pic}
          ig_id={account.id}
          key={index}
          removeAccount={removeAccount}
        />
      ))}
      {newAccounts.map((account, index) => (
        <IgAccountCard 
          username={account.username}
          avatar={account.profile_pic}
          ig_id={account.id}
          key={index}
          newIg={true}
          removeAccount={removeAccount}
        />
      ))}
    </div>
  )
}

const IgAccountCard = ({
  username, 
  avatar, 
  removeAccount, 
  ig_id,
  newIg = false
}) => {

  return(
    <div className="column is-one-third">
      <Card
        style={{
          width: 220,
          marginTop: 16
        }}
        actions={[
          <CloseCircleOutlined 
            key="delete" 
            onClick={()=>removeAccount(ig_id, newIg)}
          />
        ]}
      >
        <Meta 
          avatar={
            <Avatar 
              src={avatar}
            />
          }
          title={username}
        />
      </Card>
    </div>
  )
}

export default ShowAccounts