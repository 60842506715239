import React, {useState} from "react";
import {Form, Input, Button, Spin} from "antd";
import {AddFaqSiteApi} from "../../Common/ApiCall/FAQApi";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddFaqSite = ({back}) => {
  const [load, setLoad] = useState(false)

  const onFinish = values => {
    setLoad(true)
    AddFaqSiteApi(values.site_name)
      .then(r => {
        if(r.success) {
          SuccessNotif("Added Successfully")
          back()
        } else {
          BadNotif("Failed", r)
        }
        setLoad(false)
      })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return(
    <div>
      <Form
        {...layout}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Site Name"
          name="site_name"
          rules={[{required: true, message: 'Please input Site Name'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout} >
          {load ? (
            <Button type="primary" >
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>

      </Form>
    </div>
  )
}

export default AddFaqSite