import React from "react";
import ShowPaypalUserSubscription from "./ShowPaypalUserSubscription";


class ShowPaypalUsers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props, 'Line 10 | Class: ShowPaypalUsers | Function: render: ')
    return <React.Fragment>
      {this.props.users.map(u => {
        console.log(u, 'Line 12 | Class: ShowPaypalUsers | Function: : ')
        return <ShowPaypalUserSubscription {...u} />
      })}
    </React.Fragment>
  }
}

export default ShowPaypalUsers