import { PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { AgencyPassword, GenerateAgencyUrl, RunServer } from "./ApiUrl";

const ChangePassword = async (user_id: string, new_password: string) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateAgencyUrl("/change_password"), {
      user_id,
      pwd: AgencyPassword,
      new_password
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {}
}

export default ChangePassword
