import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from "./auth-header";

const AddTrainingItem = async (item_title, is_live, is_blog, item_link, item_excerpt) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/add_training_item"), {
        item_title, is_live, is_blog, item_link, item_excerpt
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

export const DeleteTrainingItem = async (item_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/remove_training_item"), {
      item_id
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

function dummyPlans() {
  return {
    data: {},
    success: true,
    errors: []
  }
}

export default AddTrainingItem
