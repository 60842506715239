import { Avatar, Button, Card, Form, Input, InputNumber, message } from 'antd'
import {CreateIgUserApi} from '../../Common/ApiCall/GrowpadV2ApiCalls/CreateIgUserApi'
import React, { useState } from 'react'
import SendNotification, { BadNotif } from '../../Common/Utils/SendNotification';
import GetIgDataFromUsername from '../../Common/ApiCall/GetIgDataFromUsername'
import ApiError from '../../Common/Models/ApiError';
import NotificationTypeEnum from '../../Common/Models/NotificationTypeEnum';
import { CloseCircleOutlined } from '@ant-design/icons';

const {Search} = Input
const {Meta} = Card

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};


const AddIgUser = () => {
  const [igUser, setIgUser] = useState(null)
  const [load, setLoad] = useState(false)

  function addIgUser(values) {
    if(!igUser) {
      message.error("Select a Ig Account First")
      return
    }
    CreateIgUserApi(igUser.username, igUser.id, values.trial_dates)
      .then(r => {
        if(r.success) {
          message.success(`${igUser.username} User Created Successfully`)
        } else {
          BadNotif('', r)
        }
      })
  } 

  async function searchUser(username) {
    if(!username) {
      message.warning("Enter Username to Continue")
      return
    }
    setLoad(true)
    let userData = await GetIgDataFromUsername(username)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif("Instagram username does not exist")
      }
      setLoad(false)
      return
    }
    if(!userData.id) {
      SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      setLoad(false)
      return
    }
    setIgUser(userData)
    setLoad(false)
  }

  return(
    <>
      <Form
        {...layout}
        hideRequiredMark
        onFinish={addIgUser}
        initialValues={{
          username: '',
          trial_dates: 90
        }}
      >
        <Form.Item
          label="Ig User"
        >
          {
            igUser ? (
              <Card style={{ width: 300, marginTop: 16 }}>
                <Meta
                  avatar={
                    <Avatar
                      src={igUser.profile_pic}
                    />
                  }
                  title={igUser.username}
                />
                <CloseCircleOutlined
                  className="cursor-pointer"
                  onClick={()=>setIgUser(null)}
                />
              </Card>
            ) : (
              <Search 
                placeholder="johndoe"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={searchUser}
                loading={load}
                disabled={load}
              />
            )
          }
        </Form.Item>
        <Form.Item
          name="trial_dates"
          rules={[{required: true}]}
          label="Trial Dates From Now"
        >
          <InputNumber 
            min={0}
          />
        </Form.Item>
        {igUser && (
          <Form.Item

          {...tailLayout}
        >
          <Button type="primary" htmlType="submit" loading={load} disabled={load}>
            Continue
          </Button>
        </Form.Item>
        )}
      </Form>
    </>
  )
}

export default AddIgUser