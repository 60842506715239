import React, {useEffect, useState} from "react";
import PlainContentWrapper from "../../Components/PlainContentWrapper";
import {Button, Col, Divider, Row, Typography} from "antd";
import ContentWrapper from "../../Components/ContentWrapper";
import IbMenuPanel from "./IbMenuPanel";
import Coupons from "./Coupons";
import Plans from "./Plans";

const {Title} = Typography

const items = [
    {
        key: 1,
        title: "Admin Panel",
        show: false
    },
    {
        key: 2,
        title: "Coupons",
        show: true
    },
    {
        key: 3,
        title: "Plans",
        show: true
    }
]

const IbMainPanel = ({init, setKey}) => {
    const [curr, setCurr] = useState(1)

    useEffect(() => {
/*        setCurr(2)*/
    }, [])

    function getTitle() {
        let links = items
        for(let i=0;i<links.length;i++) {
            if(links[i].key === curr) {
                return links[i].title
            }
        }
    }

    const getComponent = () => {
        switch (curr) {
            case 1: return <IbMenuPanel links={items} setCurr={setCurr} />
            case 2: return <Coupons />
            case 3: return <Plans />
            default: return <IbMenuPanel links={items} setCurr={setCurr} />
        }
    }

    return (
        <PlainContentWrapper marginLess>
            <Row justify="center" gutter={24}>
                <Col lg={{span: 22}} md={{span: 24}}>
                    <ContentWrapper marginLess>
                        <Title level={2} style={{fontWeight: 'medium'}}>
                            {getTitle()}
                            {curr!==1 ? (
                                <Button className="menu-float-right" onClick={()=>setCurr(1)} >
                                    Back
                                </Button>
                            ) : null}
                        </Title>
                        <Divider style={{borderTop: '1px solid black'}} />
                        {getComponent()}
                    </ContentWrapper>
                </Col>
            </Row>
        </PlainContentWrapper>
    )
}

export default IbMainPanel