import React, {useEffect, useState} from 'react'
import {Row, Col, Typography, Divider, Button} from 'antd'
import ContentWrapper from "../../Components/ContentWrapper";
import PlainContentWrapper from "../../Components/PlainContentWrapper";
import FAQAdminPanel from './FAQMainPanel';
import AddFaqSite from './AddFaqSite';
import AllFaqSites from './ShowAllFaq';

const {Title} = Typography

const links = [
  {
    show: false,
    title: "FAQ Admin Panel",
    id: 1
  },
  {
    show: true,
    title: "All FAQ Sites",
    id: 2
  },
  {
    title: 'Add FAQ Site',
    id: 3,
    show: true
  }
]

const PanelOptions = ({setCurr}) => {
  function ChangeMenu(curr) {
    setCurr(curr)
  }

  return(
    <>
      {links.map((item, index) => (
        item.show ? (
            <Button style={{marginBottom: '0.8rem'}} type="primary" block size="large" key={index} onClick={ChangeMenu(item.id)} >
              {item.title}
            </Button>
          ) : null
      ))}
      </>
  )
}

const FAQMainPanel = ({init, setKey}) => {
  const [curr, setCurr] = useState(1)

  useEffect(()=>{
    init()
  }, [])

  function getComponent() {
    switch (curr) {
      case 1: return <FAQAdminPanel links={links} setCurr={setCurr} />
      case 2: return <AllFaqSites />
      case 3: return <AddFaqSite back={()=>setCurr(1)} />
      default: return <PanelOptions setCurr={setCurr} />
    }
  }

  function getTitle() {
    for(let i=0;i<links.length;i++) {
      if(links[i].id === curr) {
        return links[i].title
      }
    }
  }

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 20}}>
          <ContentWrapper marginLess>
            <Title level={2} style={{fontWeight: 'medium'}}>
              {getTitle()}
              {curr!==1 ? (
                <Button className="menu-float-right" onClick={()=>setCurr(1)} >
                  Back
                </Button>
              ) : null}
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            {getComponent()}
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default FAQMainPanel