import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetFAQSites = async (email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/faq_sites"), {}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {
      id: 2,
      email: '2man@man.com',
      stripe_customer_id: 's_123',
      is_payed: true,
      satan_calls_remaining: 2000,
      extra_level_2_calls: 0,
      extra_satan_calls: 0,
      trial_expiry_time: '',
      created_at: 'Thu, 22 Oct 2020 20:20:02 +0000',
      updated_at: 'Thu, 22 Oct 2020 20:20:02 +0000'
    },
    success: true,
    errors: []
  }
}

export default GetFAQSites
