import ApiConstants from "./ApiConstants";

export default function ibAuthHeader() {
    const token = ApiConstants.ibAuthKey
    if(token) {
        return {Authorization: `Bearer ` + token}
    }
    else {
        return {

        } ;
    }
}
