import React, {useState} from "react";
import {Button, Divider, Form, Input, Modal, Typography} from "antd";
import LoginToken from "../Common/ApiCall/LoginToken";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import {Link} from "react-router-dom";
import {setToken} from '../Common/ApiCall/Logout'

const layout = {
  wrapperCol: {
    lg: {
      span: 20,
      offset: 2
    },
    md: {
      span: 24
    }
  },
};
const tailLayout = {
  wrapperCol: {
    lg: {
      span: 20,
      offset: 2
    },
    md: {
      span: 24
    }
  },
};

const {Title} = Typography

const SignIn = ({setUser}) => {
  const [visible, setVisible] = useState(false)
  const [load, setLoad] = useState(false)

  function onFinish(values) {
    setLoad(true)
    LoginToken(values.username, values.password)
      .then(r => {
        setLoad(false)
        //console.log(r)
        if(r.success) {
          setToken(r.data.token)
          setUser()
        }
        else {
          SendNotification(NotificationTypeEnum.Failure, 'Bad Credentials')
        }
      })
  }

  return(
    <div>
      <Form
        {...layout}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
      >
        <Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!'
              }
            ]}
            noStyle
          >
            <Input
              bordered={false}
              placeholder="Username"
              size="large"
              type="text"
            />
          </Form.Item>
          <Divider className="black-divider" />
        </Form.Item>

        <Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
                min: 8
              }
            ]}
          >
            <Input.Password
              placeholder="Password"
              bordered={false}
              size="large"
            />
          </Form.Item>
          <Divider className="black-divider" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <div className="align-center">
            <Button 
              className="dark-button" 
              size="large" 
              type="primary" 
              htmlType="submit"
              loading={load}
            >
              LOGIN
            </Button>
          </div>
        </Form.Item>

        <Form.Item {...tailLayout} hidden >
          <div className="align-center">
            <Title level={4}>
              Don't have an Account?
            </Title>
            <Button
              block
              size="large"
              style={{
                background: 'transparent linear-gradient(180deg, #001529 80%, #546270 100%) 0% 0% no-repeat padding-box',
                padding: '2px 15px'
              }}
              type="primary"
            >
              <Link to={'/sign-up'}>
                SIGN UP
              </Link>
            </Button>
          </div>
        </Form.Item>

      </Form>

    </div>
  )

}

export default SignIn