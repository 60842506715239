import React, { useEffect, useState } from 'react'
import {
  Typography,
  Row, Col,
  Button,
  Space,
  AutoComplete,
  Modal
} from 'antd'
import PlainContentWrapper from '../../Components/PlainContentWrapper'
import QueryEmail from '../../Common/ApiCall/ProfilebudAgency/QueryEmail'
import { BadNotif } from '../../Common/Utils/SendNotification'
import EditUser from './EditUser'
import Item from 'antd/lib/list/Item'
import GetDetailedUser from '../../Common/ApiCall/ProfilebudAgency/GetDetailedUser'
import Loader from '../../Components/Loader'
import AllTasksWithDetails from "./AllTasksWithDetails";

const { Title } = Typography
const {Option} = AutoComplete

const Agency = ({
  init
}: any) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [uId, setUid] = useState('')
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])

  const onSearch = (text: string) => {
    if (text.length < 1) {
      return
    }
    QueryEmail(text)
      .then((r: any) => {
        if (r.success) {
          setOptions(r.data)
        } else {
          BadNotif('', r)
        }
      })
  }

  function getUser(userId = uId) {
    setLoading(true)
    GetDetailedUser(userId)
      .then(r => {
        setLoading(false)
        if (r.success) {
          setUser(r.data)
        } else {
          BadNotif('Getting User Failed', r)
        }
      })
  }

  const onSelect = (userId: string) => {
    setUid(userId)
    getUser(userId)
  }

  const refresh = () => getUser()
  const back = () => {
    setUser(null)
    setUid('')
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <PlainContentWrapper>
      <Row
        justify='center'
        align='middle'
        gutter={24}
      >
        <Col
          md={{ span: 24 }}
          lg={{span: 20}}
        >
          <LoadingModal
            loading={loading}
          />
          <Title>
            Profilebud Agency Admin
          </Title>
          {
            user ?
              (
                <EditUser
                  refresh={refresh}
                  back={back}
                  user={user}
                />
              ) :
              (
                <Space>
                  <Title level={4}>
                    Get Agency User by Email
                  </Title>
                  <AutoComplete
                    value={query}
                    style={{
                      width: 300,
                    }}
                    placeholder="Enter Email to Continue"
                    onSearch={onSearch}
                    onChange={e => setQuery(e)}
                    onSelect={onSelect}
                  >
                    {options.map((option: any) => (
                      <Option
                        key={option.id}
                        value={option.id}
                        title={option.email}
                      >
                        {option.email}
                      </Option>
                    ))}
                  </AutoComplete>
                </Space>
              )
          }
        </Col>
      </Row>
      <Row
          justify='center'
          align='middle'
          gutter={24}>
        <Col
            md={{ span: 24 }}
            lg={{span: 20}}>

          <AllTasksWithDetails />

        </Col>
      </Row>

    </PlainContentWrapper>
  )
}

const LoadingModal = ({
  loading
}: any) => {

  return (
    <Modal
      visible={loading}
      footer={null}
    >
      <Loader />
    </Modal>
  )
}

export default Agency