import ContentWrapper from "../../Components/ContentWrapper";
import React from "react";
import IbMainPanel from "./IbMainPanel";

const Influencerbit = () => {

    return (
        <IbMainPanel />
    )
}

export default Influencerbit