import { PostRequest, GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";

const GROWPAD_SECRET = 'AFE7DDFF45F5D96FA5AAF34C81C4A'

export const GrowpadGetDetailedUserByEmail = async (email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_email", 'growpad'), {
      email,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

export const GrowpadGetDetailedUserIgId = async (ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_ig_id", 'growpad'), {
      ig_id,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

export const GrowpadQueryUserById = async (user_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_id", 'growpad'), {
      user_id,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadQueryEmailApi = async (query_email) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await GetRequest(GenerateUrl("/query_email", 'growpad'), {
        query_email,
        growpad_secret: GROWPAD_SECRET
      })
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const GrowpadAddUserApi = async (email, password, has_base_product = true, expiry_time, ig_id, ig_username) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest(GenerateUrl("/add_user", 'growpad'), {
        password,
        email,
        premium: has_base_product,
        growpad_secret: GROWPAD_SECRET,
        expiry_time,
        ig_id,
        ig_username
      })
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
  };

export const GrowpadAddAccessTokenApi = async (name, email = '', info = '', days = 30, expire_in_days = 30) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/add_access_token", 'growpad'), {
      email, name, info, days, expire_in_days,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadGetAllTokens = async() => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/get_access_tokens", 'growpad'), {
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadGetDetailedUserByIgUsername = async (username) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_username", 'growpad'), {
      username,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadChangePayedUserApi = async (user_id, is_payed) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_payed_user", 'growpad'), {
      user_id, is_payed,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadChangeExiryTime = async (user_id, expiry_time) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_expiry_time", 'growpad'), {
      user_id,
      expiry_time,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadChangePasswordApi = async (user_id, password) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_password", 'growpad'), {
      user_id,
      password,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadUpdateIgAccount = async (user_id, ig_id, username) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/update_ig_account", 'growpad'), {
      user_id,
      ig_id,
      username,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GrowpadRemoveIgAccount = async (user_id, ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/remove_ig_account", 'growpad'), 
      {
        user_id,
        ig_id,
        growpad_secret: GROWPAD_SECRET
      }, 
    )
    } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
}

export const GrowpadRemoveUserApi = async (user_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/remove_user", 'growpad'), {
      user_id,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

function dummyPlans() {
  return {
    data: {
      id: 2,
      email: '2man@man.com',
      stripe_customer_id: 's_123',
      is_payed: true,
      satan_calls_remaining: 2000,
      extra_level_2_calls: 0,
      extra_satan_calls: 0,
      trial_expiry_time: '',
      created_at: 'Thu, 22 Oct 2020 20:20:02 +0000',
      updated_at: 'Thu, 22 Oct 2020 20:20:02 +0000'
    },
    success: true,
    errors: []
  }
}
