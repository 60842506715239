import React from "react";
import ConvertToReadableDateTime from "../../Common/Utils/ConvertToReadableDateTime";
import {Button, Divider, Spin, Table} from "antd";
import * as copy from "copy-to-clipboard";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import {GetRequest, PostRequest} from "../../Common/ApiCall/Requests";


class NotWorkingAccountsFix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notWorkingAccountLog: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getNotWorkingAccounts();
  }

  getNotWorkingAccounts = async () => {
    this.setState({isLoading: true})
    let res = await GetRequest("https://api3.profilebud.com/api/admin/v1/not_working_account_logs?last_n=100&pwd=some_random_password_3788sjkkdfsdf")
    if (res.isValid()) {
      let notWorkingAccountLog = res.body.data;
      this.setState({
        notWorkingAccountLog
      })
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, "Not Working Accounts Call Failed")
      SendNotification(NotificationTypeEnum.Failure, res.error);
    }
    this.setState({isLoading: false})
  }

  testAccount = async (ig_account_id) => {
    console.log(ig_account_id, 'Line 37 | Class: NotWorkingAccountsFix | Function: testAccount: ')
    let data = {
      ig_account_id
    }
    let res = await PostRequest("https://api3.profilebud.com/api/admin/v1/test_and_fix_working_account", {ig_account_id: ig_account_id, pwd: "some_random_password_3788sjkkdfsdf"})
    if (res.isValid()) {
      SendNotification(NotificationTypeEnum.Success, "Account Fixed :)")
      this.getNotWorkingAccounts()
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, res.error.errors[0].message);
    }
  };

  makeAccountBad = async (ig_account_id) => {
    console.log(ig_account_id, 'Line 37 | Class: NotWorkingAccountsFix | Function: testAccount: ')
    let data = {
      ig_account_id
    }
    let res = await PostRequest("https://api3.profilebud.com/api/admin/v1/make_account_bad", {ig_account_id: ig_account_id, pwd: "some_random_password_3788sjkkdfsdf"})
    if (res.isValid()) {
      SendNotification(NotificationTypeEnum.Success, "Account deleted")
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, res.error.errors[0].message);
    }
  };


  render() {
    const columns = [
      {
        title: 'IG Account ID',
        dataIndex: 'ig_account_id',
        key: 'ig_account_id'
      },
      {
        title: 'Failed Task Type',
        dataIndex: 'task_type',
        key: 'task_type'
      },
      {
        title: 'Account Creation Date',
        dataIndex: 'details',
        key: 'details_creation',
        render: d => {if (d && d.account_creation_date) {return ConvertToReadableDateTime(d.account_creation_date)} else {return ""}}
      },
      {
        title: 'Counts',
        dataIndex: 'details',
        key: 'details_counts',
        render: d => {if (d && d.counts) {
          let email = d.counts['EMAIL']
          let follower = d.counts['FOLLOWER']
          let hashtag = d.counts['HASHTAG']
          return `Email: ${email} | Follower: ${follower} | Hashtag" ${hashtag}`
        } else {return ""}}
      },
      {
        title: 'Error',
        dataIndex: 'details',
        key: 'details',
        render: d => {if (d) {return d.error} else {return ""}}
      },
      {
        title: 'Account Gone Bad',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => ConvertToReadableDateTime(text)
      },
      {
        title: 'Proxy',
        dataIndex: 'details',
        key: 'details_proxy',
        render: d => {if (d && d.proxy) {
          if (d.same_proxy_count) {
            return `Same Proxy Count: ${d.same_proxy_count} || Proxy: ${d.proxy.value}`
          }
          return d.proxy.value
        } else {return ""}}
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <React.Fragment>
          <Button onClick={() => {
            this.testAccount(record.ig_account_id)
          }}>
            Test
          </Button>
            &nbsp;&nbsp;
            <Button type={"danger"} onClick={() => {
              this.makeAccountBad(record.ig_account_id)
            }}>
              Make Bad
            </Button>

          </React.Fragment>
        ),
      },

    ];
    return <React.Fragment>
      <div style={{textAlign: "center"}}>
        <h2>Not Working Account Log</h2>
        {this.state.isLoading ? <Spin /> : <React.Fragment />}
        <br/>
        <Button onClick={() => {this.getNotWorkingAccounts()}}>Refresh</Button>
        <br />
        <Table
          columns={columns}
          dataSource={this.state.notWorkingAccountLog} />
        <br />
      </div>
      <Divider style={{borderTop: '1px solid black'}}/>

    </React.Fragment>
  }
}

export default NotWorkingAccountsFix