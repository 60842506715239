import React from "react";
import {Button, DatePicker, Divider} from "antd";
import ContentWrapper from "../../Components/ContentWrapper";
import ShowPaypalUsers from "./ShowPaypalUsers";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import GetPaypalUsers from "../../Common/ApiCall/GrowpadV2ApiCalls/GetPaypalUsers";

const { RangePicker } = DatePicker;

class GrowpadPaypalUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startEpoch: 0,
      endEpoch: 0,
      paypalUsers: []
    }
  }
  
  convertStringToEpoch = (d) => {
    return new Date(d).getTime()/1000;
  };

  getPaypalUsers = async () => {
    if (this.state.startEpoch === 0 || this.state.endEpoch === 0) {
      SendNotification(NotificationTypeEnum.Failure, "Set Correct Dates")
      return
    }

    let res = await GetPaypalUsers(this.state.startEpoch, this.state.endEpoch)
    if (!res || !res.data) {
      SendNotification(NotificationTypeEnum.Failure, "Error in API");
      return;
    }
    this.setState({paypalUsers: res.data})
    console.log(res, 'Line 32 | Class: GrowpadPaypalUsers | Function: getPaypalUsers: ')
    // Make API Call here

  };

  render() {
    console.log(this.state, 'Line 20 | Class: GrowpadPaypalUsers | Function: render: ')
    return <React.Fragment>
      <RangePicker onChange={(a,datesString) => {
        let sd = datesString[0];
        let ed = datesString[1];
        this.setState({
          startEpoch: this.convertStringToEpoch(sd),
          endEpoch: this.convertStringToEpoch(ed)
        })
      }}/><br />
      <Button onClick={this.getPaypalUsers}>Get Paypal Users</Button>
      <Divider style={{borderTop: '1px solid black'}} />
      <ShowPaypalUsers users={this.state.paypalUsers}/>
    </React.Fragment>
  }
}

export default GrowpadPaypalUsers