import { PostRequest, GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";

const GROWPAD_SECRET = 'AFE7DDFF45F5D96FA5AAF34C81C4A'

const UpdateSubscriptionExpiryTime = async (subscription_id, expiry_time) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/update_subscription_expiry_time", 'growpad', true), {
      subscription_id, expiry_time,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {},
    success: true,
    errors: []
  }
}

export default UpdateSubscriptionExpiryTime