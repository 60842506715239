import { ClockCircleFilled, DownloadOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons'
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Button,
  Card,
  message,
  Table,
  Space,
  Tooltip,
  Progress
} from 'antd'
import React, { useEffect, useState } from 'react'
import ChangeCoins from '../../Common/ApiCall/ProfilebudAgency/ChangeCoins';
import ChangeEmail from '../../Common/ApiCall/ProfilebudAgency/ChangeEmail';
import ChangePassword from '../../Common/ApiCall/ProfilebudAgency/ChangePassword';
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';
import moment from 'moment'

const dateFormat = "DD/MM/YYYY HH:mm:ss"

function renderDateToDateTime(epoch: number) {
  return moment(new Date(epoch*1000)).format(dateFormat)
}

function date(date_1: any, dateFormat1 = dateFormat) {
  if(!date_1) return moment(new Date(), dateFormat1)
  let date = new Date(date_1)
  return moment(date, dateFormat1)
}

function roundToTwo(num: number) {    
  return +(Math.round(Number(String(num) + "e+2"))  + "e-2");
}


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const {Meta} = Card
const {Title} = Typography

type EditUserProps = {
  refresh: Function,
  back: Function,
  user: any
}

const EditUser = ({
  refresh,
  back,
  user
}: EditUserProps) => {
  const [userForm] = Form.useForm()
  const [load, setLoad] = useState(false)
  const [, forceUpdate] = useState()

  const transactionCols = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Payment handler by',
      dataIndex: 'payment_handler',
      render: (i: string) => i === "Paypal" ? "PAYPAL" : "Card (STRIPE)",
    },
    {
      title: 'Status',
      dataIndex: 'is_payment_done',
      render: (i: boolean) => (<span>{i ? "COMPLETED" : "INCOMPLETE"}</span>),
    },
    {
      title: 'Date',
      dataIndex: 'data_time_created',
      render: (i: any) => renderDateToDateTime(i),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
    },
    {
      title: 'Final Price',
      render: (i: any) => {
        return (<span>{`${i.currency==="INR" ? "₹" : "$" }${roundToTwo(i.total_price / 100)}`}</span>)
      },
    },
    {
      title: 'Coins',
      dataIndex: 'coins',
    }
  ]

  const taskcols = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id"
  },
  {
      title: "Name",
      dataIndex: "category",
      render: (i: any) => i.name,
  },
  {
      title: "Date Submitted",
      dataIndex: "date_time_submitted",
      render: (i: number) => renderDateToDateTime(i),
  },
  {
      title: "Progress",
      dataIndex: "progress",
      render: (i: any) => (
          (i.done) ?
          <div className="is-flex is-justify-content-center is-align-items-center" style={{width: "100%"}}>
              <span className="has-text-weight-bold is-size-5" style={{color: "#FFB027"}}>COMPLETED</span>
          </div> :
          <Tooltip title={i.done + " / " + i.total}>
              <Progress
                  strokeColor={{
                      "0%": "#FFDD00",
                      "100%": "#FEA000",
                  }}
                  percent={Math.round((i.done * 100) / i.total)}
                  status="active"
              />
          </Tooltip>
      ),
  },
/*   {
      title: "Actions",
      render: (i: any) => (
          <Space size={20}>
              {i.progress.done >= i.progress.total ? (
                  <>
                      <Button
                          icon={<DownloadOutlined className="is-size-6-desktop is-size-7-touch" />}
                          type="text"
                      />
                      <Button
                          icon={<FilterOutlined className="is-size-6-desktop is-size-7-touch" />}
                          type="text"
                      />
                  </>
              ) : (
                      <Button
                          icon={<ClockCircleFilled className="is-size-6-desktop is-size-7-touch"/>}
                          type="text"
                      />
              )}
          </Space>
      ),
      width: "10%"
  }, */
  ]

  function changePassword() {
    setLoad(true)
    ChangePassword(user.user.id, userForm.getFieldValue('password'))
      .then(r => {
        setLoad(false)
        if (r.success) {
          message.success("Changed Password")
          refresh()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changeEmail() {
    setLoad(true)
    ChangeEmail(user.user.id, userForm.getFieldValue('email'))
      .then(r => {
        setLoad(false)
        if (r.success) {
          message.success("Changed Password")
          refresh()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changeCoins() {
    setLoad(true)
    ChangeCoins(user.user.id, userForm.getFieldValue('coins'))
      .then(r => {
        setLoad(false)
        if (r.success) {
          message.success("Changed Password")
          refresh()
        } else {
          BadNotif('', r)
        }
      })
  }

  function updateUser(values: any) {
    if (values.coins !== user.user.coins) {
      changeCoins()
    }
    if (values.password !== user.user.password) {
      changePassword()
    }
    if (values.email !== user.user.email) {
      changeEmail()
    }
  }

  function init() {
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Title level={3}>
        Edit User Panel
        <Space style={{float: 'right'}}>
          <Button
            shape='circle'
            style={{
              float: 'right',
              marginRight: '10px'
            }}
            onClick={() => refresh()}
            icon={<ReloadOutlined />}
          />
          <Button
            onClick={() => back()}
          >
            Back
          </Button>
        </Space>
      </Title>

      <Form
        {...layout}
        form={userForm}
        onFinish={updateUser}
        hideRequiredMark
        initialValues={{
          email: user.user.email,
          password: user.user.password,
          coins: user.user.coins,
          stripe_customer_id: user.user.stripe_customer_id,
          name: user.user.name,
          created_at: date(user.user.created_at)
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true }]}
        >
          <Input
            type='email'
            placeholder="User Email"
          />
        </Form.Item>
        <Form.Item
          name="coins"
          label="Coins"
          rules={[{required: true}]}
        >
          <Input
            type='number'
            placeholder="Coins"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{
            required: true,
            min: 8
          }]}
        >
          <Input
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          label="Stripe Customer Id"
          name="stripe_customer_id"
        >
          <Input
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
        >
          <Input
            disabled
          />
        </Form.Item>
        <Form.Item
          name="created_at"
          label="Created At"
        >
          <DatePicker
            disabled
            format={dateFormat}
          />
        </Form.Item>
        <Form.Item
          {...tailLayout}
          shouldUpdate
        >
          {() => (
            <Button
              htmlType='submit'
              type='primary'
            >
              Update
            </Button>
          )}
        </Form.Item>
      </Form>
      <Table
        bordered
        dataSource={user.transactions}
        loading={load}
        columns={transactionCols}
        rowKey="id"
        size='small'
      />
      <Table
        bordered
        dataSource={user.tasks}
        loading={load}
        columns={taskcols}
        rowKey='id'
        size='small'
      />
    </>
  )
}

export default EditUser