import { GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import { AgencyPassword, GenerateAgencyUrl, RunServer } from "./ApiUrl";

const QueryEmail = async (query: string) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateAgencyUrl("/query_email"), {
      query,
      pwd: AgencyPassword
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {}
}

export default QueryEmail
