import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";
import GrowpadConstant from './GrowpadConstant'

export const GetPaypalUsers = async (start_date, end_date) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/paypal_users", 'growpad', true), {
      start_date: start_date,
      end_date: end_date,
      growpad_secret: GrowpadConstant.GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: [
      {
        plan_name: "Test Plan",
        growpad_email: "abhinav@test.com",
        paypal_subscription_id: "paypal_subscription_id",
        expiry_date: "expiry_date",
        paypal_email: "paypal@email.com",
        paypal_name: "abhinav",
        paypal_status: "ACTIVE",
        ig_users: [
          {ig_id: "1234", username: "abhnv_rai", ig_email: "ig@email.com"}
        ]
      }
    ],
    success: true,
    error: null,
  };
}

/*
V2User
id: 250,
email: "jamesmassenburg2000@gmail.com",
password: "Ittr1234",
stripe_customer_id: "cus_J01IcM1vkHMZdZ",
created_at: "2021-03-16 18:04:53",
updated_at: "2021-03-16 19:39:52"
*/

/*
V2Subscription
id: 367,
v2_user_id: 365,
v2_product_id: 11,
stripe_subscription_id: "sub_JBGxtVX2LxQmpX",
paypal_subscription_id: nil,
expiry_time: "2021-04-25 14:48:10",
created_at: "2021-03-25 14:44:07",
updated_at: "2021-03-25 14:48:10",
status: "incomplete",
coupon_code: nil
*/

export default GetPaypalUsers