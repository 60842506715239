import { Input, message, Button, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import NotificationTypeEnum from '../../Common/Models/NotificationTypeEnum'
import SendNotification from '../../Common/Utils/SendNotification'
import GetIgDataFromUsername from '../../Common/ApiCall/GetIgDataFromUsername'
import ApiError from '../../Common/Models/ApiError'
import Loader from '../../Components/Loader'
import {
  CheckOutlined
} from '@ant-design/icons'

const SearchBox = ({maxAccounts, accounts, setigAccounts, username}) => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

/*   useEffect(()=>{
    username && makeDetailedUserFromUsername(username)
  }, []) */

/*   if(maxAccounts <= accounts.length) {
    return null
  } */

  function searchUser() {
    let search = true
    if(!query) {
      SendNotification(NotificationTypeEnum.Failure, "Enter Instagram Username to Continue")
      search = false
    } else if (accounts.length) {
      for(let i=0; i< accounts.length; i++) {
        if(accounts[i].username === query) {
          SendNotification(NotificationTypeEnum.Failure, "User has already been added.")
          search = false
        }
      }
    }
    if(search) { // True for dev 
      makeDetailedUserFromUsername(query)
    }
  }

  async function makeDetailedUserFromUsername(query) {
    setLoading(true)
    let userData = await GetIgDataFromUsername(query) 
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        message.error("Instagram username does not exist", 6)
      }
      setLoading(false)
      return
    }
    if(userData.biography === "COULD_NOT_ACCESS_THIS_USER") {
      setLoading(false)
      message.error("Please check the username entered and try again", 10)
      return
    }
/*     let userData = {
      id: query.length, username: query, profile_pic: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
    } */
/*     let new_accounts = accounts.slice()
    new_accounts.push(userData) */
    setigAccounts([userData])
/*     setigAccounts(new_accounts) */
    setQuery('')
    setLoading(false)
  }

  return(
    <>
      <Space>
        <Input 
          placeholder="johndoe"
          style={{
            maxWidth: '500px'
          }}
          size="large"
          value={query}
          onChange={e => setQuery(e.target.value)}
          onPressEnter={()=>searchUser()}
        />
        <Button
          size="large"
          onClick={searchUser}
          loading={loading}
        >
          <CheckOutlined />
        </Button>
      </Space>
      {loading && <Loader />}
    </>
  )
}

export default SearchBox