import React from "react";


class Box extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let style = {
      margin: "3%",
      backgroundColor: "#F0F2F5",
      textAlign: "center",
      padding: "3%"
    }
    if (this.props.color) {
      style.backgroundColor = this.props.color
    }
    if (this.props.type && this.props.type === "left") {
      style.backgroundColor = "#c6f2c1"
    }
    if (this.props.type && this.props.type === "done") {
      style.backgroundColor = "#d68998"
    }

    return <div style={style}>
      <h2>{this.props.title}</h2>
      <div style={{fontSize: "180%"}}>{this.props.value}</div>
    </div>
  }
}

export default Box