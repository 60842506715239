import React, {useEffect} from 'react'
import {Row, Col, Button, Menu} from 'antd'
import PlainContentWrapper from "../../Components/PlainContentWrapper";

const FAQAdminPanel = ({setCurr, links}) => {

  //useEffect(()=>setCurr(3))

  return(
    <PlainContentWrapper>
      <Row justify="center" align="middle" gutter={24}>
        <Col md={{span: 24}} lg={{span: 20}}>
          <Menu mode="inline" defaultSelectedKeys={['1']} onClick={key=>{console.log(key);setCurr(parseInt(key.key))}} >
          {links.map((item, index) => (
            item.show ? (
                <Menu.Item key={item.id} >
                  <Button className="is-spaced-bottom" block size="large" type="primary" key={item.id}>
                    {item.title}
                  </Button>
                </Menu.Item>
              ): null
          ))}
          </Menu>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default FAQAdminPanel