import React, {useEffect, useState} from "react";
import {Form, Input, Avatar, Typography, DatePicker, Divider, Card, InputNumber, Tooltip, Radio, Button, Switch, Spin, message, Table, Skeleton} from "antd";
import GetDetailedUserByEmail from "../../Common/ApiCall/GetDetailedUserByEmail";
import {CloseCircleOutlined, ReloadOutlined} from '@ant-design/icons'
import Loader from "../../Components/Loader";
import { ChangePasswordApi, QueryUserById, RemoveIgAccount, ChangePayedUserApi, ChangeVIPCalls, GetDetailedUserEmail, GetDetailedUserIgId, RemoveUserApi, ChangeExpiryTime } from "../../Common/ApiCall/UserApi";
import { BadNotif, SuccessNotif } from "../../Common/Utils/SendNotification";
import moment from 'moment'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const {Meta} = Card
const {Title} = Typography

function date(date_1, dateFormat = 'DD/MM/YYYY') {
  if(!date_1) return moment(new Date(), dateFormat)
  let date = new Date(date_1)
  return moment(date, dateFormat)
}

export const IgUserPanel = ({ig_id, back, visitUser}) => {
  const [load, setLoad] = useState(false)
  const [initloader, setInitLoader] = useState(true)
  const [data, setData] = useState({})
  const [level2CallsForm] = Form.useForm()

  function init() {
    GetDetailedUserIgId(ig_id)
      .then(r => {
        if(r.success) {
          setData(r.data)
          setInitLoader(false)
        }
      })
  }

  function RefreshData() {
    GetDetailedUserIgId(ig_id)
      .then(r => {
        if(r.success) {
          setData(r.data)
        }
      })
  }

  function goToUser() {
    QueryUserById(data.user_id)
      .then(r => {
        if(r.success) {
          visitUser(r.data.user.email)
        } else {
          BadNotif('', r)
        }
      })
  }

  function changeExpiryTime() {
    setLoad(true)
    let t = level2CallsForm.getFieldValue('trial_expiry_time').format('YYYY-MM-DD HH:mm:ss')
    let e = level2CallsForm.getFieldValue('extra_satan_calls')
    let l2 = level2CallsForm.getFieldValue('extra_level_2_calls')
    ChangeExpiryTime(t, ig_id, e, l2)
      .then(r => {
        if(r.success) {
          message.success("Updated User Details")
        } else {
          BadNotif('', r)
        }
        setLoad(false)
        RefreshData()
      })
  }

  useEffect(()=>{
    init()
  }, [])

  if(initloader) {
    return <Loader />
  }

  return (
    <>
    <Title>
      <Tooltip title="Refresh User Data" >
        <Button shape="circle" onClick={RefreshData} style={{float: 'right', marginRight: '10px'}} >
          <ReloadOutlined
            style={{fontSize: '12px'}}
          />
        </Button>
      </Tooltip>
      <Divider />
    </Title>

    {
    load ? (
      <Title>
        <Spin />
      </Title>
    ) : null
    }

    <Form
      {...layout}
      form={level2CallsForm}
      hideRequiredMark
    >
      <Form.Item
        name="ig_id"
        label="Ig Id"
        rules={[{required: true}]}
        initialValue={data.ig_id}
      >
        <Input
          disabled
          type="email"
        />
      </Form.Item>

      <Form.Item
        name="username"
        label="Ig Username"
        rules={[{required: true}]}
        initialValue={data.username}
      >
        <Input
          disabled
        />
      </Form.Item>

      <Form.Item
        name="ig_email"
        label="Ig Email"
        rules={[{required: true}]}
        initialValue={data.ig_email}
      >
        <Input
          disabled
        />
      </Form.Item>

      {
        data.user_id === null ? (
          <>
        <Form.Item
          name="trial_expiry_time"
          label="Trial Expiry Time"
          rules={[{required: true}]}
          initialValue={data.trial_expiry_time ? date(data.trial_expiry_time) : date()}
        >
          <DatePicker />
      </Form.Item>
      <Form.Item
        name="extra_satan_calls"
        label="Trial Satan Call"
        rules={[{required: true, type: 'number'}]}
        initialValue={data.extra_satan_calls}
      >
        <InputNumber
          min={0}
        />
      </Form.Item>
      <Form.Item
        name="extra_level_2_calls"
        label="Trial Level 2 Call"
        rules={[{required: true, type: 'number'}]}
        initialValue={data.extra_level_2_calls}
      >
        <InputNumber
          min={0}
        />
      </Form.Item>
      <Form.Item
        {...tailLayout}
        shouldUpdate
      >
        {()=>{
          return (
            level2CallsForm.isFieldsTouched() ? (
              <Button onClick={changeExpiryTime}>
                Save
              </Button>
            ) : null
          )
        }}
      </Form.Item>
          </>
        ) : null
      }

      <Form.Item 
        {...tailLayout}
      >
        {
          data.user_id ? (
            <Button type="primary" onClick={goToUser} >
            View / Edit User
          </Button>
          ) : (
            <>
              No Account Associated
            </>
          )
        }
      </Form.Item>

    </Form>

    </>
  )
}

function getRoundedPrice (price) {
  price = (price/100).toFixed(2)
  return price
}

function momentObjectToEpoch(object) {
  return (object.valueOf())/1000
}

function epochToMoment(epoch) {
  return moment(epoch*1000)
}

const paymentColumns = [
  {
    key: 'price',
    dataIndex: 'price',
    title: 'Price',
    render: i => getRoundedPrice(i)
  },
  {
    key: 'currency',
    dataIndex: 'currency',
    title: 'Currency'
  },
  {
    key: 'payment_handler',
    title: 'Handled By',
    render: i => {
      if(i.payment_handler) return i.payment_handler
      else {
        if(i.session_id) return "Stripe"
      }
      return null
    }
  },
  {
    key: 'discount_code',
    dataIndex: 'discount_code',
    title: 'Applied Discount'
  },
  {
    key: 'is_payment_done',
    dataIndex: 'is_payment_done',
    title: 'Payment Done',
    render: i => i ? "Yes" : "No"
  },
  {
    key: 'created_on',
    dataIndex: 'created_at',
    title: 'Date',
    render: i => (
      date(i).format("DD/MM/YYYY HH:mm:ss")
    )
  }
]

const UserPanel = ({user_email, back}) => {
  const [load, setLoad] = useState(false)
  const [query, setQuery] = useState('')
  const [mailForm] = Form.useForm()
  const [satanCallsForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [, forceUpdate] = useState()
  const [IGForm] = Form.useForm()
  const [oldPasswordForm] = Form.useForm()
  const [whitelabelForm] = Form.useForm()
  const [user, setUser] = useState(null)
  const [ig_accounts, set_ig_accounts] = useState([])
  const [payments, setPayments] = useState([])
  const [initloader, setInitLoader] = useState(true)

  function RefreshData() {
    setLoad(true)
    GetDetailedUserEmail(user_email)
      .then(r => {
        setLoad(false)
        setUser(r.data.user)
        set_ig_accounts(r.data.ig_accounts)
        setPayments(r.data.payments)
        oldPasswordForm.setFieldsValue({password: r.data.user.password})
        satanCallsForm.setFieldsValue({satan_calls_remaining: r.data.user.satan_calls_remaining})
        whitelabelForm.setFieldsValue({whitelabel_accounts: r.data.user.whitelabel_accounts})
        setInitLoader(false)
      })
  }

  function getUserData(email) {
    GetDetailedUserEmail(email)
      .then(r => {
        setUser(r.data.user)
        set_ig_accounts(r.data.ig_accounts)
        setPayments(r.data.payments)
        setInitLoader(false)
      })
  }

  function changePassword(values) {
    setLoad(true)
    ChangePasswordApi(user_email, values.new_password)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('Password Changed')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changeSatanCalls(values) {
    setLoad(true)
    ChangeVIPCalls(user_email, values.satan_calls_remaining) 
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('Updated')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changePayedUser(values) {
    setLoad(true)
    ChangePayedUserApi(user_email, values.is_payed)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('User Permission Changed')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function deleteUserAccount() {
    setLoad(true)
    RemoveUserApi(user_email)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('User Deleted')
          back()
        } else {
          BadNotif('', r)
        }
      })
  }

  function removeIgAccount(ig_id) {
    RemoveIgAccount(ig_id)
    .then(r=>{
      if(r.success) {
        SuccessNotif('IG Account Removed')
        init()
      } else {
        BadNotif(r)
      }
    })
  }

  async function makeDetailedIDFromUsernames() {
    /*setLoad(true)
    let userData = await (query)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif("Instagram username does not exist");
      }
      setLoad(false)
      return
    }
    Add(user_email,userData.id, userData.username, userData.profile_pic)
      .then(r => {
        if(r.success) {
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoad(false)
        RefreshData()
      })
      */
  }

  function init() {
    RefreshData()
    forceUpdate({})
  }

  useEffect(()=>{
    init()
  }, [])

  if(initloader) {
    return <Skeleton />
  }

  return(
    <>
      <Title>
        <Tooltip title="Refresh User Data" >
          <Button shape="circle" onClick={RefreshData} style={{float: 'right', marginRight: '10px'}} >
            <ReloadOutlined
              style={{fontSize: '12px'}}
            />
          </Button>
        </Tooltip>
        <Divider />
      </Title>

{
  load ? (
    <Title>
      <Spin />
    </Title>
  ) : null
}

      <Form
        {...layout}
        form={mailForm}
        hideRequiredMark
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{required: true}]}
          initialValue={user.email}
        >
          <Input
            disabled
            type="email"
            placeholder="Enter Email here to Change"
          />
        </Form.Item>
      </Form>

      <Form
      onValuesChange={changePayedUser}
        {...layout}
      >
        <Form.Item
          name="is_payed"
          label="Is Payed User"
        >
          <Switch
            checked={user.is_payed}
          />
        </Form.Item>
      </Form>

      <Form
        {...layout}
      >
        <Form.Item
          name="stripe_customer_id"
          initialValue={user.stripe_customer_id}
          label="Stripe Customer Id"
        >
          <Input disabled />
        </Form.Item>
      </Form>

      {user.is_payed ? (
        <Form
          form={satanCallsForm}
          onFinish={changeSatanCalls}
          hideRequiredMark
          {...layout}
        >
          <Form.Item
            label="Satan Calls Remaining"
            name="satan_calls_remaining"
            rules={[{required: true, type: 'number', min: 0}]}
            initialValue={user.satan_calls_remaining}
          >
            <InputNumber
            />
          </Form.Item>
          <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!satanCallsForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
      ): null}

      {
        user.is_payed && (
          <Form
            form={whitelabelForm}
            hideRequiredMark
            {...layout}
          >
            <Form.Item
              label="Whitelabel Accounts"
              name="whitelabel_accounts"
              rules={[{required: true, type: 'number', min: 0}]}
              initialValue={user.whitelabel_accounts}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        )
      }

      {user.is_payed ? (
        <Form
          hideRequiredMark
          {...layout}
        >
          <Form.Item
            label="Level 2 Calls Remaining"
            name="level_2_calls_remaining"
            initialValue={"UNLIMITED"}
            rules={[{required: true, type: 'number', min: 0}]}
          >
            <Input
              disabled
            />
          </Form.Item>
        </Form>
      ): null}

      <Form
        {...layout}
      >
        <Form.Item
          label="Instagram Accounts"
        >
          {/*ig_accounts.length < 3 ? (
                          <Input.Search
                          onPressEnter={makeDetailedIDFromUsernames}
                          onSearch={makeDetailedIDFromUsernames}
                          value={query}
                          placeholder="Enter Username"
                          size="large"
                          onChange={e=>setQuery(e.target.value)}
                        />
          ) : null*/}
          {ig_accounts.length > 0 ? (
            ig_accounts.map((item, index) => (
              <Card style={{ width: 300, marginTop: 16 }}>
                <Meta
                  avatar={
                    <Avatar
                      src={item.profile_pic}
                    />
                  }
                  title={item.username}
                />
                <CloseCircleOutlined
                  className="cursor-pointer"
                  onClick={()=>removeIgAccount(item.ig_id)}
                />
              </Card>
            ))
          ): (
            <div>
              No Associated Accounts
            </div>
          )}
        </Form.Item>
      </Form>

      <Form
        {...layout}
        form={oldPasswordForm}
      >
        <Form.Item
          label="Password"
          name="password"
          initialValue={user.password}
        >
          <Input 
            disabled
          />
        </Form.Item>
      </Form>

      <Form
        {...layout}
        hideRequiredMark
        form={passwordForm}
        onFinish={changePassword}
      >
        <Form.Item
          name="new_password"
          label="Change Password"
          rules={[{
            required: true,
            min: 8,
            max: 24
          }]}
        >
          <Input
            placeholder="Enter Password here to Change"
            maxLength={24}
            minLength={8}
          />
        </Form.Item>
        <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!passwordForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
      </Form>

      <Form
        {...layout}
        onFinish={deleteUserAccount}
      >
        <Form.Item
          {...tailLayout}
        >
          <Button type="primary" htmlType="submit" danger>
            Delete Account
          </Button>
        </Form.Item>
      </Form>

      <Table 
        dataSource={payments}
        columns={paymentColumns}
        expandable={{
          expandedRowRender: (paymentToken) => (
              !paymentToken.is_payment_done ? (
                <div>
                  Payment Not Done
                </div>
              ) : (
                <div className="content">
                  <b>Payer Email: </b> {paymentToken.payer_email}
                  <br />
                  <b>Payer Id: </b> {paymentToken.payer_id}
                  <br />
                  <b>Order Id: </b> {paymentToken.order_id}
                </div>
              )            
          ),
          rowExpandable: token => token.payment_handler === "Paypal"
        }}
      />
    </>
  )
}

export default UserPanel