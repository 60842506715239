import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from "./auth-header";

export const QueryEmailApi = async (query_email) => {
    let apiResponse;
    if (RunServer || process.env.NODE_ENV !== "development") {
      apiResponse = await GetRequest(GenerateUrl("/query_email"), {
        query_email
      }, authHeader())
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

export const QueryUserById = async (user_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_id"), {
      user_id
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const QueryUserByPaypalEmail = async (paypal_email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/get_detailed_user_by_paypal_email"), {
      paypal_email
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const QueryUserByPaypalId = async (paypal_payment_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/get_detailed_user_by_ids"), {
      paypal_payment_id
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GetDetailedUserEmail = async (email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_email"), {
      email
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const GetDetailedUserIgId = async (ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_ig_id"), {
      ig_id
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyIgUser(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

function dummyIgUser() {
  return {"data":{"id":4607,"ig_id":"5894368402","username":"_a_gryffindor","ig_email":"2000.aman.sinha@gmail.com","user_id":null,"extra_satan_calls":2000,"extra_level_2_calls":24000,"trial_expiry_time":"2020-11-17T00:00:00.000Z","created_at":"2020-11-12T13:37:51.908Z","updated_at":"2021-03-19T18:51:24.068Z"},"success":true,"error":null}
}

export const GetDetailedUserByIgUsername = async (username) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user_by_ig_username"), {
      username
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const ChangeExpiryTime = async (expiry_time, ig_id, e, l2) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_expiry_time"), {
      trial_expiry_time: expiry_time,
      ig_id,
      extra_satan_calls: e,
      extra_level_2_calls: l2
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const ChangePasswordApi = async (email, new_password) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_password"), {
      email, new_password
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const ChangePayedUserApi = async (email, is_payed) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_payed_user"), {
      email, is_payed
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const ChangeVIPCalls = async (email, vip_calls) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_vip_calls"), {
      email, vip_calls
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const ChangeLevel2Api = async (email, level_2_calls) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/change_level_2_calls"), {
      email, level_2_calls
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

export const AddIgAccount = async (ig_id, username, email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/add_ig_account"), 
      {ig_id, username, email}, authHeader() )
    } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
}

export const RemoveIgAccount = async (ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/remove_ig_account"), 
      {ig_id}, authHeader() )
    } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
}

export const RemoveUserApi = async (email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/remove_user"), {
      email
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
}

function dummyPlans() {
    return {
      data: "ok",
      success: true,
      errors: []
    }
  }
  