import { ReloadOutlined } from '@ant-design/icons'
import { 
  Col, 
  Form, 
  Typography, 
  Row, 
  Input, 
  Skeleton, 
  Tooltip, 
  Button, 
  Divider,
  Tag,
  Table, 
  Space,
  message,
  Popconfirm,
  Modal,
  DatePicker
} from 'antd'
import React, { useEffect, useState } from 'react'
import GetDetailedUserObject from '../../Common/ApiCall/GrowpadV2ApiCalls/GetDetailedUserObject'
import { BadNotif } from '../../Common/Utils/SendNotification'
import CancelSubscription from '../../Common/ApiCall/GrowpadV2ApiCalls/CancelSubscription'
import ChangePassword from '../../Common/ApiCall/GrowpadV2ApiCalls/ChangePassword'
import UpdateSubscriptionExpiryTime from '../../Common/ApiCall/GrowpadV2ApiCalls/UpdateSubscriptionExpiryTime'
import moment from 'moment'
import { useForm } from 'antd/lib/form/Form'
import ChangeEmail from '../../Common/ApiCall/GrowpadV2ApiCalls/ChangePassword'
import AddIgUserSubscription from '../../Common/ApiCall/GrowpadV2ApiCalls/AddIgUserSubscription'
import RemoveIgUserSubscription from '../../Common/ApiCall/GrowpadV2ApiCalls/RemoveIgUserSubscription'
import AddIgAccounts from './AddIgAccounts'
import Loader from '../../Components/Loader'
import {Line} from '@ant-design/charts'

const {Title} = Typography

const dateFormat = "DD/MM/YYYY"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function date(date_1, format = dateFormat) {
  const dateFormat1 = format;
  let date = new Date(date_1)
  return moment(date, dateFormat1)
}

const UserPanel = ({userId}) => {
  const [user, setUser] = useState(null)
  const [subs, setSubs] = useState([])
  const [load, setLoad] = useState(true)
  const [cLoad, setCLoad] = useState(false)
  const [mainForm] = useForm()
  const [changePasswordModal, setChangePassword] = useState(false)
  const [changeExpiryModal, setChangeExpiryModal] = useState(false)
  const [igModal, setIgModal] = useState(false)
  const [defDate, setDefDate] = useState(null)
  const [subsId, setSubsId] = useState(null)
  const [igAccounts, setIgAccounts] = useState([])
  const [igLimit, setIgLimit] = useState(null)

  const expiryModalClose = () => {setSubsId(null);setChangeExpiryModal(false)}
  const expiryModalOpen = (id, date) => {setSubsId(id);setDefDate(date);setChangeExpiryModal(true)}

  const closePasswordModal = () => {setChangePassword(false)}
  const openPasswordModal = () => {setChangePassword(true)}

  const openIgModal = (i, ig_accounts) => {setIgLimit(i.accounts_limit);setIgAccounts(i.ig_accounts);setSubsId(i.id);setIgModal(true)}
  const closeIgModal = (refresh = false) => {
    setSubsId(null);
    setIgModal(false);
    refresh && refreshData()
  }

  function getUserDetails(userId) {
    setLoad(true)
    GetDetailedUserObject(userId)
      .then(r => {
        if(r.success) {
          setUser(r.data.user)
          setSubs(r.data.subscriptions)
        } else {
          BadNotif('', r)
        }
        setLoad(false)
      })
  }

  function refreshData() {getUserDetails(userId)}

  function changeExpirySubscriptionFunction(values) {
    let t = values.expiry.format('YYYY-MM-DD HH:mm:ss')
    setLoad(true)
    UpdateSubscriptionExpiryTime(subsId, t)
      .then(r => {
        if(r.success) {
          message.success("Expiry Date Changed")
        } else {
          BadNotif('', r)
        }
        setLoad(false)
        refreshData()
      })
  }

  useEffect(()=>{
    refreshData()
  }, [])

  function cancel(e) {}

  function confirm(id) {
    setCLoad(true)
    CancelSubscription(id)
      .then(r => {
        if(r.success) {
          message.success("Subscription Cancelled")
        } else {
          BadNotif('', r)
        }
        setCLoad(false)
        refreshData()
      })
  }

  function changePasswordFunction(values) {
    setLoad(true)
    ChangePassword(userId, values.password)
      .then(r => {
        if(r.success) {
          message.success("Password Changed Successfully")
          closePasswordModal()
        } else {
          BadNotif('', r)
        }
        setLoad(false)
        refreshData()
      })
  }

  function changeEmail(values) {
    if(values.email === user.email) {
      message.warning("Email Not Changed.")
      return
    }
    setLoad(true)
    ChangeEmail(userId, values.email)
      .then(r => {
        if(r.success) {
          message.success("Email Changed")
        } else {
          BadNotif('', r)
        }
        setLoad(false)
        refreshData()
      })
  }

  const columns = [
    {
      key: 'id',
      title: 'Payment Handled by',
      dataIndex: 'payment_method'
    },
    {
      key: 'subscription_id',
      title: 'Subscription Id',
      render: i => (
        i.payment_method.toLowerCase() === "paypal" ? i.paypal_subscription_id : (
          i.payment_method.toLowerCase() === "card (stripe)" ? i.stripe_subscription_id : "N/A"
        )
      )
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: i => (
        date(i).format("DD/MM/YYYY HH:mm:ss")
      )
    },
    {
      title: 'Ig Accounts',
      render: (i) => `${i.ig_accounts.length} / ${i.accounts_limit}`
    },
    {
      title: 'Usernames',
      dataIndex: 'ig_accounts',
      render: arr => (
        <>
          {arr.map((account) => {
            return (
              <Tag key={account.username}>
                {account.username}
              </Tag>
          )})}
        </>
      )
    },
    {
      key: 'cycle',
      title: 'Billing Cycle',
      dataIndex: 'billing_cycle_in_months',
      render: (i) => i===1 ? "Monthly": "Yearly"
    },
    {
      key: 'expiry_time',
      title: 'Expiry Time',
      render: i => (
        <DatePicker 
          format={dateFormat}
          value={date(i.expiry_time)}
          onClick={()=>expiryModalOpen(i.id, i.expiry_time)}
          open={false}
        />
      )
    },
    {
      title: 'Actions',
      key: 'x',
      render: (item) => (
        <Space>
          <Button
            onClick={()=>openIgModal(item)}
          >
            Change Ig Accounts
          </Button>
          {item.status.toLowerCase() !== 'active' ? (
            <div>
              {item.status}
            </div>
          ) : (
            <Popconfirm
              title="Are you sure you want to cancel the subscription"
              onConfirm={()=>confirm(item.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">
                Cancel
              </Button>
            </Popconfirm>
          )}
        </Space>
      )
    }
  ]

  const lineConfig = {
    width: 800,
    height: 400,
    autoFit: false,
    xField: 'created_at',
    yField: 'follower_count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  }

  const followersColumns = [
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Recorded At',
      render: i => (
        date(i).toDate().toUTCString()
      )
    },
    {
      key: 'follower_count',
      dataIndex: 'follower_count',
      title: 'Followers Count'
    }
  ]
  
  return(
    <Row justify="center" gutter={24}>
      <Col span={24}>
      <ChangeExpiryDateModal
        changeExpiryModal={changeExpiryModal}
        changeExpirySubscriptionFunction={changeExpirySubscriptionFunction}
        closeExpiryModal={expiryModalClose}
        defaultValue={defDate}
      />
      <PasswordChangeModal 
        closePasswordModal={closePasswordModal}
        changePasswordFunction={changePasswordFunction}
        changePasswordModal={changePasswordModal}
      />
      <IgModal 
        visible={igModal}
        ig_accounts={igAccounts}
        subscription_id={subsId}
        closeModal={closeIgModal}
        userId={userId}
        setIgAccounts={setIgAccounts}
        limit={igLimit}
      />
      <Title>
        <Tooltip title="Refresh User Data" >
          <Button shape="circle" onClick={refreshData} style={{float: 'right', marginRight: '10px'}} >
            <ReloadOutlined
              style={{fontSize: '12px'}}
            />
          </Button>
        </Tooltip>
        <Divider />
      </Title>
        {
          load ? (
            <Skeleton 
              active
            />
          ) : (
            <Form
              {...layout}
              form={mainForm}
              onFinish={changeEmail}
              initialValues={user && {
                email: user.email,
                password: user.password
              }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{type: 'email', required: true}]}
              >
                <Input
                />
              </Form.Item>
              <Form.Item
                shouldUpdate
                {...tailLayout}
              >
                {()=>
                  <Button
                    htmlType="submit"
/*                     disabled={mainForm.getFieldValue("email") !== user.email} */
                  >
                    Update
                  </Button>
                }
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
              >
                <Input.Password 
                  disabled
                />
              </Form.Item>
              <Form.Item
                {...tailLayout}
              >
                <Button type="primary" onClick={openPasswordModal}>
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          )
        }
        <Table 
          columns={columns}
          dataSource={subs}
          loading={load || cLoad}
          expandable={{
            expandedRowRender: (subscription) => 
              (subscription.ig_accounts.map((igAccount) => (
                <div
                  key={igAccount.username}
                >
                  {console.log(igAccount.historic_follower_count)}
                  <p className="has-text-weight-bold">{igAccount.username}</p>
                  {
                    igAccount.historic_follower_count.length>0 ? (
                      <Table 
                        size="small"
                        dataSource={igAccount.historic_follower_count}
                        columns={followersColumns}
                      />
/*                       <Line 
                        data={igAccount.historic_follower_count}
                        {...lineConfig}
                      /> */
                    ) : (
                      <p>
                        No Followers Data
                      </p>
                    )
                  }
                </div>
              )))
            }
          }
        />
      </Col>
    </Row>
  )
}

const PasswordChangeModal = ({changePasswordModal, closePasswordModal, changePasswordFunction}) => {

  return(
    <Modal
      visible={changePasswordModal}
      onCancel={closePasswordModal}
      footer={null}
      title={"Change Password"}
    >
      <Form
        {...layout}
        onFinish={changePasswordFunction}
      >
        <Form.Item
          rules={[{required: true, min: 7}]}
          label="Create Password"
          name="password"
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...tailLayout}
        >
          <Button
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ChangeExpiryDateModal = ({changeExpiryModal, closeExpiryModal, changeExpirySubscriptionFunction, defaultValue}) => {

  return(
    <Modal
      visible={changeExpiryModal}
      onCancel={closeExpiryModal}
      footer={null}
      title="Change Subscription Expiry Time"
    >
      <Form
        onFinish={changeExpirySubscriptionFunction}
        initialValues={{
          expiry: date(defaultValue)
        }}
      >
        <Form.Item
          name="expiry"
          rules={[{required: true}]}
        >
          <DatePicker 
            allowClear={false}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const IgModal = ({
  visible,
  ig_accounts,
  subscription_id,
  closeModal,
  limit,
  userId,
  setIgAccounts
}) => {
  const [load,setLoad] = useState(false)
  const [newAccounts, setNewAccounts] = useState([])
  
  const addIgAccount = (accounts) => {
    setLoad(true)
    AddIgUserSubscription(userId, subscription_id, accounts)
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Account Added")
          closeModal(true)
        } else {
          BadNotif('', r)
        }
      })
  }

  const removeIgAccount = (ig_id) => {
    setLoad(true)
    RemoveIgUserSubscription(userId, subscription_id, ig_id)
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success('Account Removed')
          closeModal(true)
        } else {
          BadNotif('', r)
        }
      })
  }

  return(
    <Modal
      visible={visible}
      onCancel={()=>closeModal()}
      footer={null}
      title={"Add/Remove Ig Accounts"}
      width={800}
    >
      <AddIgAccounts 
        accounts={ig_accounts}
        newAccounts={newAccounts}
        setNewAccounts={setNewAccounts}
        nextPage={addIgAccount}
        maxAccounts={limit}
        setAccounts={setNewAccounts}
        removeIg={removeIgAccount}
        addIg={addIgAccount}
        username={null}
      />
      {load && <Loader />}
    </Modal>
  )

}

export default UserPanel