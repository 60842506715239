import React, { useState , useEffect} from 'react'
import {Button, Popconfirm, Input, Space, Select, InputNumber, Checkbox, Table, Spin, Form, Breadcrumb, Typography, Switch} from 'antd'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';
import { AddFaqItemApi, DeleteFaqItemApi, GetFaqGroups, GetFaqItemsGroup, GetFaqItemsSite, UpdateFaqItemApi } from '../../Common/ApiCall/FAQApi';
import Loader from '../../Components/Loader'

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
const tailLayout = {
    wrapperCol: {offset: 6, span: 14}
}
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
const AddtailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
  
const {Option} = Select
const {Title} = Typography
const {TextArea} = Input

const AllFaqItems = ({site, group, back, all_groups = true}) => {
    const [items, setItems] = useState([])
    const [init, setInit] = useState(true)
    const [editor, setEditor] = useState(false)
    const [add, setAdd] = useState(false)
    const [faq_item_id, set_faq_item_id] = useState(-1)
    const [faq_item, set_faq_item] = useState({})
    const [popVIsible, setPopVisible] = useState(false)
    const [popId, setPopId] = useState(null)

    function deleteItem(item_id) {
        DeleteFaqItemApi(item_id)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Deleted Successfully')
                    refresh()
                } else {
                    BadNotif('', r)
                }
            })
    }

    function initial() {
        if(all_groups) {
            GetFaqItemsSite(site.site_name)
            .then(r => {
                setItems(r.data)
                setInit(false)
            })
        } else {
            GetFaqItemsGroup(group.id)
                .then(r => {
                    setItems(r.data)
                    setInit(false)
                })
        }
    }

    function refresh() {
        setInit(true)
        initial()
    }

    function makePopVis(id) {
        setPopId(id)
        setPopVisible(true)
    }

    const columns = [
        {
            title: 'Faq Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority'
        },
        {
            title: 'Is Live',
            dataIndex: 'is_live',
            render: it => it ? "YES": "NO"
        },
        {
            title: 'Action',
            key: 'id',
            render: (item) => (
                <Space size="middle">
                    <Button onClick={()=>{setEditor(true);set_faq_item_id(item.id);set_faq_item(item)}} >
                        View / Edit
                    </Button>
                    <Popconfirm  
                        title="Are you sure you want to delete this Faq?"
                        okText="Yes"
                        cancelText="No"
                        visible={popVIsible && popId===item.id}
                        onConfirm={()=>{setPopVisible(false);deleteItem(item.id)}}
                        onCancel={()=>{setPopVisible(false)}}
                    >
                        <Button type="danger" onClick={()=>makePopVis(item.id)} >
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    useEffect(() => {
        initial()
    }, [])

    if(init) {
        return <Loader />
    }

    if(add) {
        return(
            <>
            <Title level={3} >
                Add Faq Item
                <Button className="menu-float-right" onClick={()=>setAdd(false)} >
                  Back to All
                </Button>
            </Title>
                <AddFaqItem  
                    site={site}
                    group={group}
                    refresh={refresh}
                    back={()=>{setAdd(false)}}
                />
            </>
        )
    }

    if(editor) {
        return(
            <>
            <Title level={2}>
                 FAQ Item Panel
                <Button className="menu-float-right" onClick={()=>setEditor(false)} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {site.site_name}
                </Breadcrumb.Item>
                {
                    group ? (
                        <Breadcrumb.Item>
                        {group.name}
                    </Breadcrumb.Item>
                    )
                    : null
                }
                <Breadcrumb.Item>
                    {faq_item.title}
                </Breadcrumb.Item>
            </Breadcrumb>
                <FAQItemEditor
                    site_name={site.site_name}
                    back={()=>{setEditor(false);refresh()}}
                    faq_item={faq_item}
                />
            </>
        )
    }

    return(
        <>
        <Button onClick={()=>setAdd(true)}>
            Add FAQ
        </Button>
        <Table 
            expandable={{
                expandedRowRender: item => <p>{item.answer}</p>
            }}
            dataSource={items}
            columns={columns}
        />
        </>
    )
}

export const AddFaqItem = ({group, site, back, refresh}) => {
    const [load, setLoad] = useState(false)
    const [init, setInit] = useState(true)
    const [groups, setGroups] = useState([])

    const onFinish = values => {
      setLoad(true)
      AddFaqItemApi(values.title, values.answer, values.is_live, group ? group.id : -1, site.id, values.priority)
        .then(r => {
          if(r.success) {
            SuccessNotif("Added Successfully")
            refresh()
            back()
          } else {
            BadNotif("Failed", r)
          }
          setLoad(false)
        })
    };

    function getGroups() {
        GetFaqGroups(site.site_name)
        .then(r => {
            setGroups(r.data)
            if(r.data.length) group = r.data[0]
            setInit(false)
        })
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(()=>{
        getGroups()
    }, [])

    if(init) {
        return(
            <Loader />
        )
    }

    return(
        <div>
        <Form
          {...layout}
          initialValues={{
              remember: true, 
              is_live: true,
              group_id: group ? group.id : null,
              priority: 0
            }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Faq Title"
            name="title"
            rules={[{required: true, message: 'Please input Faq Title'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Faq Answer"
            name="answer"
            rules={[{required: true, message: 'Please input Faq Answer'}]}
          >
            <Input.TextArea 
                allowClear
                showCount
                autoSize
            />
          </Form.Item>
          <Form.Item
            name="group_id"
            label="FAQ Group"
          >
              <Select placeholder="Select Group of FAQ">
                    {groups.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
              </Select>
          </Form.Item>
          <Form.Item 
            name='priority'
            label="Priority"
          >
              <InputNumber />
          </Form.Item>
          <Form.Item
          {...tailLayout}
          name="is_live"
          valuePropName="checked"
        >
          <Checkbox>
              Faq Is Live
          </Checkbox>
        </Form.Item>
          <Form.Item {...AddtailLayout} >
            {load ? (
              <Button type="primary" >
                <Spin />
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    )
}

const FAQItemEditor = ({faq_item, site_name, back}) => {
    const [groups,setGroups] = useState([])
    const [init, setInit] = useState(true)

    useEffect(() => {
        getGroups()
      }, []);

    function getGroups() {
        GetFaqGroups(site_name)
        .then(r => {
            setGroups(r.data)
            setInit(false)
        })
    }

    function updateItem(values) {
        UpdateFaqItemApi(faq_item.id, values.title, values.answer, values.is_live, values.group_id, values.priority)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                    back()
                } else {
                    BadNotif('', r)
                }
            })
    }

    if(init) {
        return <Loader />
    }

    const options = (
        <Select placeholder="Select Group of FAQ">
                {groups.map((item) => (
                    <Option value={item.id} key={item.id}>
                        {item.name}
                    </Option>
                ))}
        </Select>
    )
 
    return(
        <>
        <Form
            {...formItemLayout}
            onFinish={updateItem}
            hideRequiredMark
            initialValues={{
                title: faq_item.title,
                answer: faq_item.answer,
                is_live: faq_item.is_live,
                group_id: faq_item.faq_group_id,
                priority: faq_item.priority
            }}
        >
            <Form.Item
                label="Faq Title"
                name="title"
                rules={[{required: true, message: 'Please input Faq Title'}]}
            >
                <Input 
                    placeholder="Title"
                />
            </Form.Item>
            <Form.Item
            label="Faq Answer"
            name="answer"
            rules={[{required: true, message: 'Please input Faq Answer'}]}
          >
            <Input.TextArea 
                allowClear
                showCount
                autoSize
            />
          </Form.Item>
          <Form.Item
            name="group_id"
            label="FAQ Group"
          >
              {options}
          </Form.Item>
          <Form.Item
            name="priority"
            label="Priority"
          >
              <InputNumber />
          </Form.Item>
          <Form.Item
          {...tailLayout}
          name="is_live"
          valuePropName="checked"
        >
          <Checkbox>
              Faq Is Live
          </Checkbox>
        </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        </>
    )
}

export default AllFaqItems