import {GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import ApiConstants, {getIbURL} from "./ApiConstants";
import ibAuthHeader from "./ibAuthHeader";

const GetCoupons = async () => {
    let apiResponse;
    if (ApiConstants.RunIbServer || process.env.NODE_ENV !== "development") {
        apiResponse = await GetRequest(getIbURL("coupons"), {
        }, ibAuthHeader())
    } else {
        apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }

    if (apiResponse.body) {
        return apiResponse.body;
    } else {
        return apiResponse.error
    }
};

function dummyPlans() {
    return {
        data: {},
        success: true,
        errors: []
    }
}

export default GetCoupons
