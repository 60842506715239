import React, { useState, useEffect } from "react";
import { AutoComplete, Form, Typography, Input, message } from "antd";
import {
    GetDetailedUserIgId,
    QueryEmailApi,
    GetDetailedUserByIgUsername,
		QueryUserByPaypalEmail,
		QueryUserByPaypalId
} from "../../Common/ApiCall/UserApi";
import SendNotification, {
    BadNotif,
} from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";

const { Option } = AutoComplete;
const { Title } = Typography;
const { Search } = Input;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const SearchUserEmail = ({ setMail, setIg, setUser }) => {
    const [value, setValue] = useState("");
    const [options, setOptions] = useState([]);
    const [igUsernameLoad, setIgUsernameLoad] = useState(false);
    const [igIdLoad, setIgIdLoad] = useState(false);
    const [paypalMailLoad, setPaypalMailLoad] = useState(false);
    const [paypalIdLoad, setPaypalIdLoad] = useState(false);

    const onSearch = (text) => {
        if (text.length < 1) {
            return;
        }
        QueryEmailApi(text.toLowerCase()).then((r) => {
            if (r.success) {
                setOptions(r.data);
            } else {
                SendNotification(
                    NotificationTypeEnum.Failure,
                    r.errors[0].message
                );
            }
        });
    };

    const onIgIdSearch = (text) => {
        if (text.length < 1) {
            SendNotification("Enter Ig ID");
						return
        }
        setIgIdLoad(true);
        GetDetailedUserIgId(text).then((r) => {
            setIgIdLoad(false);
            if (r.success) {
                setIg(r.data.ig_id);
                setUser(true);
            } else {
                SendNotification(
                    NotificationTypeEnum.Failure,
                    r.errors[0].message
                );
            }
        });
    };

    const onIgUsernameSearch = (text) => {
        if (text.length < 1) {
            SendNotification("Enter Ig Username");
						return
        }
        setIgUsernameLoad(true);
        GetDetailedUserByIgUsername(text).then((r) => {
            setIgUsernameLoad(false);
            if (r.success) {
                setIg(r.data.ig_id);
                setUser(true);
            } else {
                SendNotification(
                    NotificationTypeEnum.Failure,
                    r.errors[0].message
                );
            }
        });
    };

    const onPaypalEmailSearch = (text) => {
        if (text.length < 1) {
            SendNotification("Enter Paypal Email");
						return
        }
        setPaypalMailLoad(true);
        QueryUserByPaypalEmail(text).then((r) => {
            setPaypalMailLoad(false);
            if (r.success) {
                if (r.data.user_found) {
                    onSelect(r.data.user.email);
                } else {
                    message.error("User Not Found");
                }
            } else {
                SendNotification(
                    NotificationTypeEnum.Failure,
                    r.errors[0].message
                );
            }
        });
    };

    const onPaypalIdSearch = (text) => {
        if (text.length < 1) {
            SendNotification("Enter Order / Payer Id");
						return
        }
        setPaypalIdLoad(true);
        QueryUserByPaypalId(text).then((r) => {
            setPaypalIdLoad(false);
            if (r.success) {
                if (r.data.user_found) {
                    onSelect(r.data.user.email);
                } else {
                    message.error("User Not Found");
                }
            } else {
                SendNotification(
                    NotificationTypeEnum.Failure,
                    r.errors[0].message
                );
            }
        });
    };

    const onSelect = (data) => {
        goToUserPanel(data);
    };
    const onChange = (data) => {
        setValue(data);
    };

    const goToUserPanel = (email) => {
        setMail(email);
        setUser();
    };

    // DEV
    /*
  useEffect(()=>{
    onSelect('2000.aman.sinha@gmail.com')
  }, [])
  */

    /*
  useEffect(()=>{
    onIgUsernameSearch('_a_gryffindor')
  }, [])
  */

    return (
        <>
            <Form {...layout}>
                <Form.Item label="Email: " rules={[{ required: true }]}>
                    <AutoComplete
                        value={value}
                        style={{ width: 200 }}
                        placeholder="Enter Email to Continue"
                        onSelect={onSelect}
                        onSearch={onSearch}
                        onChange={onChange}
                    >
                        {options.map((item) => (
                            <Option
                                key={item.id}
                                value={item.email}
                                title={item.email}
                            >
                                {item.email}
                            </Option>
                        ))}
                    </AutoComplete>
                </Form.Item>
            </Form>

            <Form {...layout}>
                <Form.Item label="Paypal Email: " rules={[{ required: true }]}>
                    <Search
                        loading={paypalMailLoad}
                        onPressEnter={(e) => onPaypalEmailSearch(e.target.value)}
                        onSearch={onPaypalEmailSearch}
                        enterButton="Search"
                        placeholder="Enter Paypal Email Here"
                    />
                </Form.Item>
            </Form>

						<Form {...layout}>
                <Form.Item label="Paypal Payer / Order Id : " rules={[{ required: true }]}>
                    <Search
                        loading={paypalIdLoad}
                        onPressEnter={(e) => onPaypalIdSearch(e.target.value)}
                        onSearch={onPaypalIdSearch}
                        enterButton="Search"
                        placeholder="Enter Payer / Order Id Here"
                    />
                </Form.Item>
            </Form>

            <Form {...layout}>
                <Form.Item label="IG USERNAME: " rules={[{ required: true }]}>
                    <Search
                        loading={igUsernameLoad}
                        onPressEnter={(e) => onIgUsernameSearch(e.target.value)}
                        onSearch={onIgUsernameSearch}
                        enterButton="Search"
                        placeholder="Enter IG Username Here"
                    />
                </Form.Item>
            </Form>

            <Form {...layout}>
                <Form.Item label="IG ID: " rules={[{ required: true }]}>
                    <Search
                        loading={igIdLoad}
                        onPressEnter={(e) => onIgIdSearch(e.target.value)}
                        onSearch={onIgIdSearch}
                        enterButton="Search"
                        placeholder="Enter IG ID Here"
                    />
                </Form.Item>
            </Form>
        </>
    );
};

export default SearchUserEmail;
