import { AutoComplete, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchByEmail from '../../Common/ApiCall/GrowpadV2ApiCalls/SearchByEmail'
import SearchByIgId from '../../Common/ApiCall/GrowpadV2ApiCalls/SearchByIgId'
import SearchByIgUsername from '../../Common/ApiCall/GrowpadV2ApiCalls/SearchByIgUsername'
import { BadNotif } from '../../Common/Utils/SendNotification'
import SearchUserBySubscriptionId from '../../Common/ApiCall/GrowpadV2ApiCalls/SearchUserBySubscriptionId'

const {Option} = AutoComplete
const {Search} = Input

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SearchUser = ({visitUser}) => {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('');
  const [load, setLoad] = useState(false)

  const onChange = (data) => {
    setValue(data)
  }
  const onSelect = (data) => {
    visitUser(data)
  }
  const onSearch = (data) => {
    if(data.length < 1) {return}
    SearchByEmail(data)
      .then(r => {
        if(r.success) {
          setOptions(r.data)
        } else {
          BadNotif('', r)
        }
      })
  }

  const onIgIdSearch = (text) => {
    if(text.length < 1) return;
    SearchByIgId(text)
      .then(r => {
        if(r.success) {
          visitUser(text, true)
        } else {
          BadNotif('', r)
        }
      })
  }

  const onIgUsernameSearch = (text) => {
    if(text.length < 1) return;
    SearchByIgUsername(text)
      .then(r => {
        if(r.success) {
          visitUser(r.data, true)
        } else {
          BadNotif('', r)
        }
      })
  }

  const onSubscriptionIdSearch = (query) => {
    if(query.length < 1) return;
    setLoad(true)
    SearchUserBySubscriptionId(query)
      .then(r => {
        setLoad(false)
        if(r.success) {
          visitUser(r.data)
        } else {
          BadNotif('', r)
        }
      })
  }

  return(
    <>
      <Form>
        <Form.Item
          noStyle
        >
          Search By Email
          <Form.Item>
            <AutoComplete
              value={value}
              style={{width: 250}}
              placeholder="Enter Email to Continue"
              onSearch={onSearch}
              onSelect={onSelect}
              onChange={onChange}
            >
              {options.map((item)=>(
                <Option key={item.id} value={item.id} title={item.email} >
                  {item.email}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Search By Stripe / Paypal Subscription Id
          <Form.Item>
            <Search 
              onSearch={onSubscriptionIdSearch}
              enterButton="Search"
              placeholder="Paypal / Stripe Subscription Id"
              onPressEnter={e => onSubscriptionIdSearch(e.target.value)}
              loading={load}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Search by Username
          <Form.Item>
            <Search 
              onSearch={onIgUsernameSearch}
              enterButton="Search"
              placeholder="Enter Ig Username here"
              onPressEnter={e=>onIgUsernameSearch(e.target.value)}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Search by Ig Id
          <Form.Item>
            <Search 
              enterButton="Search"
              placeholder="Enter Ig Id Here"
              onSearch={onIgIdSearch}
              onPressEnter={e=>onIgIdSearch(e.target.value)}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </>
  )
}

export default SearchUser