import React, {useEffect, useState} from 'react'
import { GrowpadGetAllTokens } from '../../Common/ApiCall/GrowpadApi'
import { BadNotif } from '../../Common/Utils/SendNotification'
import {Button, Tooltip, Space, Table, Form, Typography, Switch, Spin} from 'antd'
import {ReloadOutlined} from '@ant-design/icons'

const AllAccessTokens = ({goToAdd}) => {
    const [loading, setLoading] = useState(true)
    const [tokens, setTokens] = useState([])

    function GetAllTokens() {
        setLoading(true)
        GrowpadGetAllTokens()
            .then(r => {
                setLoading(false)
                if(r.success) {
                    setTokens(r.data)
                } else {
                    BadNotif('', r)
                }
            })
    }

    useEffect(()=> {
        GetAllTokens()
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Info',
            dataIndex: 'info',
            key: 'info'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        }
    ]

    return(
        <div>
            {loading ? <Spin /> : null}
            <Button 
                onClick={goToAdd} 
                style={{
                    marginBottom: '20px'
                }}
            >
                Add New Token
            </Button>
            <Tooltip title="Refresh User Data" >
          <Button shape="circle" onClick={()=>GetAllTokens()} style={{float: 'right', marginRight: '10px'}} >
            <ReloadOutlined
              style={{fontSize: '12px'}}
            />
          </Button>
        </Tooltip>
            <Table 
                dataSource={tokens}
                columns={columns}
            />
        </div>
    )
}

export default AllAccessTokens