import PlainContentWrapper from "../../Components/PlainContentWrapper";
import {Button, Col, Menu, Row} from "antd";
import React from "react";

const IbMenuPanel = ({links, setCurr}) => {

    return (
        <PlainContentWrapper>
            <Row justify="center" align="middle" gutter={24}>
                <Col md={{span: 24}} lg={{span: 20}}>
                    <Menu mode="inline" defaultSelectedKeys={['1']} onClick={key=>{setCurr(parseInt(key.key))}} >
                        {links.map((item, index) => (
                            item.show ? (
                                <Menu.Item key={item.key} >
                                    <Button className="is-spaced-bottom" block size="large" type="primary" key={item.key}>
                                        {item.title}
                                    </Button>
                                </Menu.Item>
                            ): null
                        ))}
                    </Menu>
                </Col>
            </Row>
        </PlainContentWrapper>
    )
}

export default IbMenuPanel