import React, { useState , useEffect} from 'react'
import {Button, Input, Space, Table, Popconfirm, Spin, Form, Breadcrumb, Typography, Switch, InputNumber} from 'antd'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';
import { AddFaqGroupApi, ChangeFaqGroupApi, ChangeFaqSiteNameApi, DeleteFaqGroupApi, GetFaqGroups } from '../../Common/ApiCall/FAQApi';
import AllFaqItems, { AddFaqItem } from './AllFaqItems';
import Loader from '../../Components/Loader'

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
const tailLayout = {
    wrapperCol: {offset: 6, span: 14}
}
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
const AddtailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
  

const {Title} = Typography
const {TextArea} = Input

const ShowFaqGroups = ({site, back}) => {
    const [init, setInit] = useState(true)
    const [items, setItems] = useState([])
    const [editor, setEditor] = useState(false)
    const [add, setAdd] = useState(false)
    const [showItem, setShowItem] = useState(false)
    const [add_faq, set_add_faq] = useState(false)
    const [faq_group_id, set_faq_group_id] = useState(-1)
    const [faq_group, set_faq_group] = useState({})
    const [popVIsible, setPopVisible] = useState(false)
    const [popId, setPopId] = useState(null)

    useEffect(() => {
        initial()
    }, [])

    function initial() {
        GetFaqGroups(site.site_name)
            .then(r => {
                setItems(r.data)
                setInit(false)
            })
    }

    function refresh() {
        setInit(true)
        initial()
    }

    function deleteFaqGroup(group_id) {
        DeleteFaqGroupApi(group_id)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Deleted Successfully')
                    refresh()
                } else {
                    BadNotif('', r)
                }
            })
    }

    function makePopVis(id) {
        setPopId(id)
        setPopVisible(true)
    }

    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority'
        },
        {
            title: 'Action',
            key: 'action',
            render: (item) => (
                <Space size="middle">
                    <Button onClick={()=>{console.log(item);setEditor(true);set_faq_group_id(item.id);set_faq_group(item)}} >
                        View / Edit
                    </Button>
                    <Popconfirm  
                        title="Are you sure you want to delete this Faq Group and its Items?"
                        okText="Yes"
                        cancelText="No"
                        visible={popVIsible && popId===item.id}
                        onConfirm={()=>{setPopVisible(false);deleteFaqGroup(item.id)}}
                        onCancel={()=>{setPopVisible(false)}}
                    >
                        <Button type="danger" onClick={()=>makePopVis(item.id)} >
                            Delete
                        </Button>
                    </Popconfirm>
                    <Button type="primary" onClick={()=>{setShowItem(true);set_faq_group(item);set_faq_group_id(item.id)}}>
                        Items
                    </Button>
                    <Button type="ghost" onClick={()=>{set_add_faq(true);set_faq_group(item);set_faq_group_id(item.id)}} >
                        Add Faq
                    </Button>
                </Space>
            )
        }
    ]

    if(add_faq) {
        return(
            <AddFaqItem 
                group={faq_group}
                site={site}
                back={()=>{set_add_faq(false)}}
                refresh={()=>{}}
            />
        )
    }

    if(showItem) {
        return(
            <>
            <Title>
                Faq Items
                <Button className="menu-float-right" onClick={()=>setShowItem(false)} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {site.site_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {faq_group.name}
                </Breadcrumb.Item>
            </Breadcrumb>
            <AllFaqItems 
                site={site}
                group={faq_group}
                back={()=>setShowItem(false)}
                all_groups={false}
            />
            </>
        )
    }

    if(init) {
        return <Loader />
    }

    if(add) {
        return(
            <>
            <Title>
                Add Faq Group
                <Button className="menu-float-right" onClick={()=>setAdd(false)} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {site.site_name}
                </Breadcrumb.Item>
            </Breadcrumb>
                <AddFaqGroup  
                    refresh={refresh}
                    site_id={site.id}
                    back={()=>setAdd(false)}
                />
            </>
        )
    }

    if(editor) {
        return(
            <>
            <Title level={2}>
                 FAQ Group Panel
                <Button className="menu-float-right" onClick={()=>setEditor(false)} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {site.site_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {faq_group.name}
                </Breadcrumb.Item>
            </Breadcrumb>
                <FAQGroupEditor
                    faq_group={faq_group}
                    back={()=>{setEditor(false);refresh()}}
                />
            </>
        )
    }

    return(
        <>
        <Title level={2}>
                 FAQ Groups
                <Button className="menu-float-right" onClick={()=>back()} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {site.site_name}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Button onClick={()=>setAdd(true)} >
                Add Group
            </Button>
        <Table 
            dataSource={items}
            columns={columns}
        />
        </>
    )
}

const AddFaqGroup = ({site_id, back, refresh}) => {
    const [load, setLoad] = useState(false)

    const onFinish = values => {
      setLoad(true)
      AddFaqGroupApi(values.name, site_id, values.priority)
        .then(r => {
          if(r.success) {
            SuccessNotif("Added Successfully")
            refresh()
            back()
          } else {
            BadNotif("Failed", r)
          }
          setLoad(false)
        })
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

    return(
        <div>
        <Form
          {...layout}
          initialValues={{
              remember: true,
              priority: 0
            }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Group Name"
            name="name"
            rules={[{required: true, message: 'Please input Site Name'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[{required: true, message: 'Please input Priority'}]}
          >
              <InputNumber />
          </Form.Item>
          <Form.Item {...AddtailLayout} >
            {load ? (
              <Button type="primary" >
                <Spin />
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
  
        </Form>
      </div>
    )
}

const FAQGroupEditor = ({faq_group, back}) => {
    const [nameForm] = Form.useForm()
    const [, forceUpdate] = useState()

    useEffect(() => {
        forceUpdate({});
      }, []);

    function changeSiteName(values) {
        ChangeFaqGroupApi(faq_group.id, values.name, values.priority)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                    back()
                } else {
                    BadNotif('', r)
                }
            })
    }

    return(
        <>
        <Form
            {...formItemLayout}
            form={nameForm}
            onFinish={changeSiteName}
            initialValues={{
                name: faq_group.name,
                priority: faq_group.priority
            }}
        >
            <Form.Item
                label="Group Name"
                name="name"
            >
                <Input 
                    placeholder="Group Name"
                />
            </Form.Item>
            <Form.Item
                label="Priority"
                name="priority"
            >
                <InputNumber />
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
                hidden={nameForm.isFieldsTouched(true)}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={nameForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        </>
    )
}

export default ShowFaqGroups