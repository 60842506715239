import React, {useEffect, useState} from 'react'
import {Row, Col, Typography, Divider, Button, message} from 'antd'
import ContentWrapper from "../../Components/ContentWrapper";
import PlainContentWrapper from "../../Components/PlainContentWrapper";
import GrowpadV2AdminPanel from './GrowpadV2AdminPanel'
import { BadNotif } from '../../Common/Utils/SendNotification';
import AddIgUser from './AddIgUser';
import SearchUser from './SearchUser';
import UserPanel from './UserPanel'
import IgUserPanel from './IgUserPanel'
import AddAccessToken from '../Growpad/AddAccessToken'
import AllAccessTokens from '../Growpad/AllAccessTokens'
import GrowpadPaypalUsers from "../Growpad/GrowpadPaypalUsers";

const {Title} = Typography

const links = [
  {
    show: false,
    title: "Growpad Admin Panel",
    id: 1
  },
  {
    show: true,
    title: "Add Ig User",
    id: 2
  },
  {
    title: 'Search User',
    id: 3,
    show: true
  },
  {
    title: 'Search User by IG',
    id: 4,
    show: false
  },
  {
    title: 'User Panel',
    id: 5,
    show: false
  },
  {
    title: "Ig User Panel",
    id: 7,
    show: false
  },
  {
    title: 'Add Access Token',
    id: 8,
    show: true
  },
  {
    title: 'All Access Tokens (Beta)',
    id: 9,
    show: true
  },
  {
    title: 'Paypal Users',
    id: 10,
    show: true
  }
]

const PanelOptions = ({setCurr}) => {
  function ChangeMenu(curr) {
    setCurr(curr)
  }

  return(
    <>
      {links.map((item, index) => (
        item.show ? (
            <Button type="primary" block size="large" key={index} onClick={ChangeMenu(item.id)} >
              {item.title}
            </Button>
          ) : null
      ))}
      </>
  )
}

const GrowpadV2MainPanel = ({init, setKey}) => {
  const [curr, setCurr] = useState(1)
  const [userIgId, setUserIgId] = useState('')
  const [userId, setUserId] = useState(null)

  useEffect(()=>{
    init()
  }, [])

  const back = () => {
    setCurr(1)
  }

  function getComponent() {
    switch (curr) {
      case 1: return <GrowpadV2AdminPanel links={links} setCurr={setCurr} />
      case 2: return <AddIgUser setCurr={setCurr} />
      case 3: return <SearchUser visitUser={visitUser} />
      case 5: return <UserPanel userId={userId} />
      case 7: return <IgUserPanel userIgId={userIgId} visitUser={visitUser} />
      case 8: return <AddAccessToken />
      case 9: return <AllAccessTokens goToAdd={()=>setCurr(8)} />
      case 10: return <GrowpadPaypalUsers />
      default: return <GrowpadV2AdminPanel links={links} setCurr={setCurr} />
    }
  }

  function getTitle() {
    for(let i=0;i<links.length;i++) {
      if(links[i].id === curr) {
        return links[i].title
      }
    }
  }

  function visitUser(id, ig = false) {
    ig ? igUserPanel(id) : userPanel(id)
  }

  function userPanel(id) {
    setUserId(id)
    setCurr(5)
  }

  function igUserPanel(id) {
    setUserIgId(id)
    setCurr(7)
  }

  function setUserDetails(ig = false) {
    setCurr(ig ? 7 : 5)
/*    GetDetailedUserEmail(email)
      .then(r => {
        if(r.success) {
          setData(r.data)
          
        } else {
          BadNotif('', r)
        }
      })*/
  }

  return(
    <PlainContentWrapper marginLess>
      <Row justify="center" gutter={24}>
        <Col lg={{span: 22}} md={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={2} style={{fontWeight: 'medium'}}>
              {getTitle()}
              {curr!==1 ? (
                <Button className="menu-float-right" onClick={()=>setCurr(1)} >
                  Back
                </Button>
              ) : null}
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            {getComponent()}
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default GrowpadV2MainPanel