import React, {useEffect, useState} from 'react'
import {Row, Col, Typography, Divider, Button} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import AddUser from "./Profilebud/AddUser";
import SearchUserEmail from "./Profilebud/SearchUserEmail";
import ProfilebudAdminPanel from './Profilebud/ProfilebudAdminPanel';
import UserPanel, {IgUserPanel} from "./Profilebud/UserPanel";
import AllTrainingItems from './Profilebud/AllTrainingItems';
import { GetDetailedUserEmail } from '../Common/ApiCall/UserApi';
import { BadNotif } from '../Common/Utils/SendNotification';

const {Title} = Typography

const links = [
  {
    show: false,
    title: "Profilebud Admin Panel",
    id: 1
  },
  {
    show: true,
    title: "Add User",
    id: 2
  },
  {
    title: 'Search User',
    id: 3,
    show: true
  },
  {
    title: 'Search User by IG',
    id: 4,
    show: false
  },
  {
    title: 'User Panel',
    id: 5,
    show: false
  },
  {
    title: 'Training Items',
    id: 6,
    show: true
  },
  {
    title: "Ig User Panel",
    id: 7,
    show: false
  }
]

const PanelOptions = ({setCurr}) => {
  function ChangeMenu(curr) {
    setCurr(curr)
  }

  return(
    <>
      {links.map((item, index) => (
        item.show ? (
            <Button type="primary" block size="large" key={index} onClick={ChangeMenu(item.id)} >
              {item.title}
            </Button>
          ) : null
      ))}
      </>
  )
}

const ProfilebudMainPanel = ({init, setKey}) => {
  const [curr, setCurr] = useState(1)
  const [userEmail, setUserEmail] = useState('')
  const [userIgId, setUserIgId] = useState('')

  useEffect(()=>{
    init()
  }, [])

  const back = () => {
    setCurr(1)
  }

  function getComponent() {
    switch (curr) {
      case 1: return <ProfilebudAdminPanel links={links} setCurr={setCurr} />
      case 2: return <AddUser />
      case 3: return <SearchUserEmail setIg={setUserIgId} setMail={setUserEmail} setUser={setUserDetails} setCurr={setCurr} />
      case 5: return <UserPanel back={back} user_email={userEmail} />
      case 6: return <AllTrainingItems />
      case 7: return <IgUserPanel ig_id={userIgId} back={back} visitUser={visitUser} />
      default: return <PanelOptions setCurr={setCurr} />
    }
  }

  function getTitle() {
    for(let i=0;i<links.length;i++) {
      if(links[i].id === curr) {
        return links[i].title
      }
    }
  }

  function visitUser(email) {
    setUserEmail(email)
    setUserDetails()
  }

  function setUserDetails(ig = false) {
    setCurr(ig ? 7 : 5)
/*    GetDetailedUserEmail(email)
      .then(r => {
        if(r.success) {
          setData(r.data)
          
        } else {
          BadNotif('', r)
        }
      })*/
  }

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 20}}>
          <ContentWrapper marginLess>
            <Title level={2} style={{fontWeight: 'medium'}}>
              {getTitle()}
              {curr!==1 ? (
                <Button className="menu-float-right" onClick={()=>setCurr(1)} >
                  Back
                </Button>
              ) : null}
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            {getComponent()}
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default ProfilebudMainPanel