import React from "react";
import {Button, Col, Divider, Row, Spin} from "antd";
import Box from "./Box";
import ContentWrapper from "../../Components/ContentWrapper";
import {GetRequest} from "../../Common/ApiCall/Requests";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";


class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workingAccounts: -1,
      v0UserCount: -1,
      v1UserCount: -1,
      emailUsersLeft: -1,
      unUsedProxyCount: -1,
      emailLimits: {done: -1, remaining: -1},
      followerLimits: {done: -1, remaining: -1},
      hashtagLimits: {done: -1, remaining: -1},
      similarAccountLimits: {done: -1, remaining: -1},
      likerLimits: {done: -1, remaining: -1},
      commenterLimits: {done: -1, remaining: -1},
      detailedUserTaskLimitsLimits: {done: -1, remaining: -1},
      isLoading: false
    };
  }

  async componentDidMount() {
    await this.setStatistics();
  }

  setStatistics = async () => {
    this.setState({isLoading: true})
    let res = await GetRequest("https://api3.profilebud.com/api/admin/v1/statistics?pwd=some_random_password_3788sjkkdfsdf")
    if (res.isValid()) {
      let data = res.body.data;
      this.setState({
        workingAccounts: data.working_accounts,
        v1UserCount:data.v1_user_count,
        emailUsersLeft: data.email_users_left,
        unUsedProxyCount: data.unused_proxy_count,
        v0UserCount: data.v0_user_count,
        emailLimits: data.email_limits,
        followerLimits: data.follower_limits,
        hashtagLimits: data.hashtag_limits,
        similarAccountLimits: data.similar_account_limits,
        likerLimits: data.liker_limits,
        commenterLimits: data.commenter_limits,
        detailedUserTaskLimitsLimits: data.detailed_user_task_limits,

      })
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, "Get Statistics Failed")
      SendNotification(NotificationTypeEnum.Failure, res.error);
    }
    this.setState({isLoading: false})
  };

  render() {
    return <React.Fragment>

      <div style={{textAlign: "center"}}>
        <h2>Statistics</h2>
        {this.state.isLoading ? <Spin /> : <React.Fragment />}
      </div>
      <Row>
        <Col span={4}>
          <Box color={"#9ab9e2"} title={"Working Accounts"} value={this.state.workingAccounts} />
        </Col>

        <Col span={4}>
          <Box color={"#d69ee5"} title={"V1 Users"} value={this.state.v1UserCount} />
        </Col>
        <Col span={4}>
          <Box color={"#6fcfe2"} title={"Email Users Left"} value={this.state.emailUsersLeft} />
        </Col>
        <Col span={4}>
          <Box color={"#f4ee6e"} title={"Unused Proxies"} value={this.state.unUsedProxyCount} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Commenters"} value={this.state.commenterLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Commenters"} value={this.state.commenterLimits.done} />
        </Col>
      </Row>
      <br />
      Remaining Limits
      <Row>
        <Col span={4}>
          <Box type={"left"} title={"Email"} value={this.state.emailLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Followers "} value={this.state.followerLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Hashtags"} value={this.state.hashtagLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Similar Accounts"} value={this.state.similarAccountLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Likers"} value={this.state.likerLimits.remaining} />
        </Col>
        <Col span={4}>
          <Box type={"left"} title={"Detailed User"} value={this.state.detailedUserTaskLimitsLimits.remaining} />
        </Col>
      </Row>
      <br />
      Done Today
      <Row>
        <Col span={4}>
          <Box type={"done"} title={"Email"} value={this.state.emailLimits.done} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Follower"} value={this.state.followerLimits.done} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Hashtag"} value={this.state.hashtagLimits.done} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Similar Account"} value={this.state.similarAccountLimits.done} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Likers"} value={this.state.likerLimits.done} />
        </Col>
        <Col span={4}>
          <Box type={"done"} title={"Detailed User"} value={this.state.detailedUserTaskLimitsLimits.done} />
        </Col>
      </Row>
      <br />
      <div style={{textAlign: "center"}}>
        <Button onClick={() => {this.setStatistics()}}>Refresh</Button>
      </div>
      <Divider style={{borderTop: '1px solid black'}}/>
    </React.Fragment>
  }
}

export default Statistics