import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";
import GrowpadConstant from './GrowpadConstant'

export const GetDetailedIgUserObject = async (ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_ig_user", 'growpad', true), {
      ig_id,
      growpad_secret: GrowpadConstant.GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {
      username: '_a_gryffindor',
      trial_expiry_time: "2021-03-28 10:49:07",
      created_at: "2021-03-25 10:49:07", 
      user_exists: 23
    },
    success: true,
    errors: []
  }
}

/*
V2IgUser
id: 2532, 
ig_id: "21180833262", 
username: "mentalityw", 
profile_pic: nil, 
trial_expiry_time: "2021-03-28 10:49:07", 
full_name: nil, 
ig_email: "winnermentalityonly@gmail.com", 
v2_user_id: 23, 
v2_subscription_id: nil, 
created_at: "2021-03-25 10:49:07", 
updated_at: "2021-03-25 10:49:09", 
chrome_email: "winnermentalityonly@gmail.com"
*/

export default GetDetailedIgUserObject