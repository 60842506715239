import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";
import GrowpadConstant from './GrowpadConstant'

export const SearchByEmail = async (query_email) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/query_email", 'growpad', true), {
      query_email,
      growpad_secret: GrowpadConstant.GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: [
      {
        id: 1,
        email: 'aman@mail.com'
      },
      {
        id: 2,
        email: '2aman@mail.com'
      },
      {
        id: 3,
        email: '3aman@mail.com'
      }
    ],
    success: true,
    errors: []
  }
}

export default SearchByEmail