import React, {useState} from "react";
import {Checkbox, Form, Input, Button, Spin, DatePicker, InputNumber} from "antd";
import {GrowpadAddAccessTokenApi, GrowpadAddUserApi} from "../../Common/ApiCall/GrowpadApi";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import moment from 'moment';
import ApiError from '../../Common/Models/ApiError'
import SendNotification from '../../Common/Utils/SendNotification'
import NotificationTypeEnum from '../../Common/Models/NotificationTypeEnum'
import GetIgDataFromUsername from "../../Common/ApiCall/GetIgDataFromUsername";
import IgAccountCard from './IgAccountCard'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const dateFormat = 'YYYY/MM/DD';
let date = new Date()
date.setDate(date.getDate() + 30)
const d = moment(date, dateFormat)

const AddAccessToken = () => {
  const [load, setLoad] = useState(false)
  const [token, setToken] = useState(null)

  const onFinish = values => {
    setLoad(true)
    GrowpadAddAccessTokenApi(values.name, values.email, values.info, values.days, values.expire_in_days)
    .then(r => {
      if(r.success) {
        SuccessNotif("Access Token Generated")
        setToken(r.data.value)
      } else {
        BadNotif("", r)
      }
      setLoad(false)
    })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return(
    <div>
      {
        token ? (
          <Form
          {...layout}
          initialValues={{token: token}}
        >
          <Form.Item
            name="token"
            label="Access Token"
          >
            <Input 
              disabled
            />
          </Form.Item>
          <Form.Item
          {...tailLayout}
          >
            <Button 
              onClick={()=>{
                setToken(null)
              }}
            >
              Add New Token
            </Button>
          </Form.Item>
        </Form>
        ) : null
      }
      <Form
        {...layout}
        initialValues={{
          remember: true,
          name: '',
          email: '',
          info: '',
          days: 30,
          expire_in_days: 30
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{required: true, message: 'Please input Name'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
        >
          <Input 
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="info"
          label="Info"
        >
          <Input.TextArea
          />
        </Form.Item>
        <Form.Item
          rules={[{required: true}]}
          name="days"
          label="Days"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="expire_in_days"
          label="Expires In"
          rules={[{required: true}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item {...tailLayout} >
          {load ? (
            <Button type="primary" >
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>

      </Form>
    </div>
  )
}

export default AddAccessToken