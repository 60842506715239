import React, {useEffect, useState} from "react";
import {Layout, Menu, Button} from "antd";
import logo from '../Common/Images/logo-full.png'
import {
    AppstoreAddOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    GoogleOutlined,
    DoubleRightOutlined,
    QuestionCircleOutlined, CrownOutlined, StarOutlined, InfoOutlined
} from '@ant-design/icons';
import {Switch} from "react-router";
import HomePage from "./HomePage";
import isMobile from "is-mobile";
import {Route} from "react-router";
import {Link} from "react-router-dom";
import ProfilebudMainPanel from "./ProfilebudMainPanel";
import {logout} from "../Common/ApiCall/Logout";
import FAQMainPanel from "./FAQService/FAQServiceMainPanel";
import GrowpadMainPanel from "./Growpad/GrowpadMainPanel";
import GrowpadV2MainPanel from "./GrowpadV2/GrowpadV2MainPanel";
import Leadgen from "./Leadgen/Leadgen";
import Agency from "./ProfibudAgency";
import Influencerbit from "./Influencerbit";

const {Header, Sider} = Layout

export const MenuItems = [
    {
        key: 1,
        icon: <DoubleRightOutlined />,
        title: 'Home',
        link: '/'
    },
    {
        key: 2,
        title: 'Profilebud',
        icon: <AppstoreAddOutlined />,
        link: '/profilebud'
    },
    {
        key: 5,
        title: 'FAQ Service',
        link: '/faq_service',
        icon: <QuestionCircleOutlined />
    },
    {
        key: 6,
        title: 'Growpad',
        link: '/growpad',
        icon: <GoogleOutlined />
    },
    {
        key: 7,
        title: 'Leadgen',
        link: '/leadgen',
        icon: <CrownOutlined />
    },
    {
        key: 8,
        title: 'Pb Agency',
        link: '/agency',
        icon: <StarOutlined />
    },
    {
        key: 9,
        title: "Influencerbit",
        link: "/ib",
        icon: <InfoOutlined />
    }
]

const Dashboard = ({user}) => {
    const [collapsed, toggleCollapsed] = useState(true)
    const [key, setKey] = useState(1)

    function toggle() {
        toggleCollapsed(!collapsed);
    }

    function toggleOnMobile() {
        if(isMobile()) {
            toggleCollapsed(true);
        }
    }

    useEffect(()=>toggleOnMobile(), [])

    if(user.email === "leadgenadmin") {
        return(
            <Layout className="is-fullheight">

                <Header
                    className="header has-background-white"
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        width: '100%'
                    }}
                >

                    <Menu mode="horizontal" style={{float: 'right'}} >
                        <Menu.Item key="3">
                            <Button block onClick={()=>logout()} type="primary" className="dark-button">
                                Log out
                            </Button>
                        </Menu.Item>
                    </Menu>

                </Header>

                <Layout className="site-layout" style={{marginTop: '64px'}}>

                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={toggle}
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                        }}
                    >

                        <Menu theme="dark" selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key))} defaultSelectedKeys={['1']}>
                            {MenuItems.map((item)=>(
                                <Menu.Item key={item.key} icon={item.icon}>
                                    <Link to={item.link}>
                                        {item.title}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu>

                    </Sider>

                    <Switch>

                        <Route
                            path="/leadgen"
                            exact
                            component={()=>(
                                <Leadgen
                                    init={()=>setKey(7)}
                                    setKey={setKey}
                                />
                            )}
                        />

                        <Route
                            component={() => (
                                <HomePage
                                    init={()=>setKey(1)}
                                    setKey={setKey}
                                    user={user}
                                />
                            )}
                        />

                    </Switch>

                </Layout>

            </Layout>
        )
    }

    return(
        <Layout className="is-fullheight">

            <Header
                className="header has-background-white"
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    width: '100%'
                }}
            >
                {/*         <img
          className="logo-img"
          alt="Logo"
          src={logo}
        /> */}

                <Menu mode="horizontal" style={{float: 'right'}} >
                    <Menu.Item key="3">
                        <Button block onClick={()=>logout()} type="primary" className="dark-button">
                            Log out
                        </Button>
                    </Menu.Item>
                </Menu>

            </Header>

            <Layout className="site-layout" style={{marginTop: '64px'}}>

                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={toggle}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                >

                    <Menu theme="dark" selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key))} defaultSelectedKeys={['1']}>
                        {MenuItems.map((item)=>(
                            <Menu.Item key={item.key} icon={item.icon}>
                                <Link to={item.link}>
                                    {item.title}
                                </Link>
                            </Menu.Item>
                        ))}
                    </Menu>

                </Sider>

                <Switch>


                    <Route
                        path="/profilebud"
                        exact
                        component={() => (
                            <ProfilebudMainPanel
                                init={()=>setKey(2)}
                                setKey={setKey}
                            />
                        )}
                    />

                    <Route
                        path="/faq_service"
                        exact
                        component={()=>(
                            <FAQMainPanel
                                init={()=>setKey(5)}
                                setKey={setKey}
                            />
                        )}
                    />

                    <Route
                        path="/growpad"
                        exact
                        component={()=>(
                            <GrowpadV2MainPanel
                                init={()=>setKey(6)}
                                setKey={setKey}
                            />
                        )}
                    />

                    <Route
                        path="/leadgen"
                        exact
                        component={()=>(
                            <Leadgen
                                init={()=>setKey(7)}
                                setKey={setKey}
                            />
                        )}
                    />

                    <Route
                        path="/agency"
                        exact
                        component={() => (
                            <Agency
                                setKey={setKey}
                                init={() => setKey(8)}
                            />
                        )}
                    />

                    <Route
                        path={"/ib"}
                        exact
                        component={() => (
                            <Influencerbit
                                setKey={setKey}
                                init={() => setKey(9)}
                            />
                        )}
                    />

                    <Route
                        component={() => (
                            <HomePage
                                init={()=>setKey(1)}
                                setKey={setKey}
                                user={user}
                            />
                        )}
                    />

                </Switch>

            </Layout>

        </Layout>
    )
}

export default Dashboard