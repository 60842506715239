import React, {useState} from "react";
import {Input, InputNumber, Form, Button, Divider, Spin} from "antd";
import {PostRequest} from "../../Common/ApiCall/Requests";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import ContentWrapper from "../../Components/ContentWrapper";




const SubmitProxy = () => {

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false)


  const onFinish = async  (values) => {
    setLoading(true);
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    console.log('Success:', values);
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/set_cookies_for_account", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Tested");
    }
    form.resetFields();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return <div style={{textAlign: "center"}}>
    <h2>Add Account</h2>
    {isLoading ? <Spin /> : <React.Fragment />}
    <Form
      form={form}
      name="basic"
      initialValues={{
        'meta': "$0.5 rambler",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Cookie"
        name="cookie"
        rules={[
          {
            required: true,
            message: 'Please input Cookie',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Proxy ID"
        name="proxy_id"
        rules={[
          {
            required: true,
            message: 'Enter Proxy ID',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Meta"
        name="meta"
        rules={[
          {
            required: true,
            message: 'Please input meta for account',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input Username',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input Password',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >
          Submit
        </Button>
      </Form.Item>
    </Form>
    <Divider style={{borderTop: '1px solid black'}}/>
  </div>
};


export default SubmitProxy