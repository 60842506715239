import React from "react";
import {GetRequest} from "../../Common/ApiCall/Requests";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import {Button, Divider, Spin, Table} from "antd";
import moment from "moment";
import ContentWrapper from "../../Components/ContentWrapper";
import ConvertToReadableDateTime from "../../Common/Utils/ConvertToReadableDateTime";
import * as copy from "copy-to-clipboard";


class ProxyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proxies: [],
      isLoading: false
    }
  }

  async componentDidMount() {
    await this.setProxies();
  }

  setProxies = async () => {
    this.setState({isLoading: true})
    let res = await GetRequest("https://api3.profilebud.com/api/admin/v1/unused_proxy?pwd=some_random_password_3788sjkkdfsdf")
    if (res.isValid()) {
      let proxies = res.body.data;
      this.setState({
        proxies
      })
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, "Proxies Call Failed")
      SendNotification(NotificationTypeEnum.Failure, res.error);
    }
    this.setState({isLoading: false})
  };

  getProxy = (v) => {
    return v.split("//")[1]
  };

  render() {
    const columns = [
      {
        title: 'Proxy ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => ConvertToReadableDateTime(text)
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: text => ConvertToReadableDateTime(text)
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button onClick={() => {
            copy(this.getProxy(record.value));
            SendNotification(NotificationTypeEnum.Success, "Copied")
          }}>
            Copy
          </Button>
        ),
      },
    ];
    return <React.Fragment>
      <div style={{textAlign: "center"}}>
        <h2>Unused Proxies</h2>
        {this.state.isLoading ? <Spin /> : <React.Fragment />}
        <Table
        pagination={{ pageSize: 3}}
        columns={columns}
        dataSource={this.state.proxies.sort((a,b) => {if (a.id > b.id) return 1; return -1})} />
        <br />
        <Button onClick={() => {this.setProxies()}}>Refresh</Button>
      </div>
      <Divider style={{borderTop: '1px solid black'}}/>

    </React.Fragment>
  }
}

export default ProxyList