import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from "./auth-header";

const ChangeTrainingLink = async (item_id, item_link) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/update_item_link"), {
        item_id, item_link
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {},
    success: true,
    errors: []
  }
}

export default ChangeTrainingLink
