import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";
import GrowpadConstant from './GrowpadConstant'

export const GetDetailedUserObject = async (user_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/detailed_user", 'growpad', true), {
      user_id,
      growpad_secret: GrowpadConstant.GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
      data: {
          user: {
              id: 200,
              email: "vincent.spitzer@web.de",
              password: "ficken666",
              stripe_customer_id: "cus_Iwy8wNSRAtUW9k",
              created_at: "2021-03-16T18:04:51.357Z",
              updated_at: "2021-03-16T18:04:51.357Z",
          },
          subscriptions: [
              {
                  id: 200,
                  payment_method: "Card (Stripe)",
                  billing_cycle_in_months: 1,
                  status: "active",
                  created_at: "2021-03-16T18:04:51.370Z",
                  expiry_time: "2021-04-15T14:08:03.000Z",
                  ig_accounts: [
                      {
                          id: "16508915107",
                          username: "spitzer.vincent",
                          profile_pic: null,
                      },
                  ],
              },
          ],
      },
      success: true,
      error: null,
  };
}

/*
V2User 
id: 250, 
email: "jamesmassenburg2000@gmail.com", 
password: "Ittr1234",
stripe_customer_id: "cus_J01IcM1vkHMZdZ", 
created_at: "2021-03-16 18:04:53", 
updated_at: "2021-03-16 19:39:52"
*/

/*
V2Subscription 
id: 367, 
v2_user_id: 365, 
v2_product_id: 11, 
stripe_subscription_id: "sub_JBGxtVX2LxQmpX", 
paypal_subscription_id: nil, 
expiry_time: "2021-04-25 14:48:10", 
created_at: "2021-03-25 14:44:07", 
updated_at: "2021-03-25 14:48:10", 
status: "incomplete", 
coupon_code: nil
*/

export default GetDetailedUserObject