import React from "react";
import {Button, Divider, Input, InputNumber, Modal, Spin, Table} from "antd";
import {GetRequest, PostRequest} from "../../Common/ApiCall/Requests";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import ConvertToReadableDateTime from "../../Common/Utils/ConvertToReadableDateTime";


class AllTasksWithDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedValue: '',
      tasks: [],
      maxCount: 300,
      user: "@",
      username: '',
      details: {
        completed_detailed_user_tasks: -1,
        not_completed_detailed_user_tasks: -1,
        v1_users: -1,
        email_count: -1,
        actual_users_for_email_call: -1
      },
      modalOpened: false
    }
  }

  componentDidMount() {
    this.getTasksDetails()
  }

  getTasksDetails = async () => {
    this.setState({isLoading: true})
    let res = await GetRequest("https://api3.profilebud.com/api/admin/v1/all_tasks_Details?last_n="+this.state.maxCount+"&pwd=some_random_password_3788sjkkdfsdf")
    if (res.isValid()) {
      let tasks = res.body.data;
      this.setState({
        tasks
      })
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, "All Tasks with Details call failed")
      SendNotification(NotificationTypeEnum.Failure, res.error);
    }
    this.setState({isLoading: false})
  };

  getSpecificTaskDetails = async (id) => {
    let res = await GetRequest("https://api3.profilebud.com/api/admin/v1/specific_task_details?pwd=some_random_password_3788sjkkdfsdf&task_id="+id.toString())
    if (res.isValid()) {
      console.log(res.body.data, 'Line 48 | Class: AllTasksWithDetails | Function: getSpecificTaskDetails: ')
      this.setState({details: res.body.data, modalOpened: true});
    }
    else {
      SendNotification(NotificationTypeEnum.Failure, "Specific task details failed")
      SendNotification(NotificationTypeEnum.Failure, res.error);
    }
  };

  startTask = async (id) => {
    let values = {}
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    values['id'] = id
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/start_task", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Started");
    }
  };

  stopTask = async (id) => {
    let values = {}
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    values['id'] = id
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/stop_task", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Stopped");
    }
  };

  changeMaxAllowed = async (id) => {
    let values = {}
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    values['task_id'] = id
    values['max_allowed'] = this.state.changedValue
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/change_max_allowed", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Max Allowed Changed");
    }
  };

  resetTask = async (id) => {
    let values = {}
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    values['task_id'] = id
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/reset_task", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Auto Complete task done");
    }
  };

  latest_date = async (id) => {
    let values = {}
    values['pwd'] = "some_random_password_3788sjkkdfsdf"
    values['task_id'] = id
    let r = await PostRequest("https://api3.profilebud.com/api/admin/v1/set_latest_date", values)
    if (!r.isValid()) {
      SendNotification(NotificationTypeEnum.Failure, "Some error happened");
    } else {
      SendNotification(NotificationTypeEnum.Success, "Task set to latest date");
    }
  }


  render() {
    const columns = [
      {
        title: 'Task Type',
        dataIndex: 'task_type',
        key: 'task_type'
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          if (record.is_stopped) {
            return <Button type="primary" onClick={() => {this.startTask(record.id)}}>Start</Button>
          } else {
            return <Button type="danger" onClick={() => {this.stopTask(record.id)}}>Stop</Button>
          }
        },
      },
      {
        title: 'Username',
        dataIndex: 'ig_username',
        key: 'ig_username',
      },
      {
        title: 'Main Value',
        dataIndex: 'main_value',
        key: 'main_value',
      },
      {
        title: 'Reset',
        key: 'reset',
        render: (text, record) => {
          return <Button onClick={() => {this.resetTask(record.id)}}>Reset</Button>
        },
      },
      {
        title: 'Done So Far',
        dataIndex: 'done_so_far',
        key: 'done_so_far'
      },
      {
        title: 'Change Max_Allowed',
        key: 'change_max_allowed',
        render: (text, record) => {
          return <React.Fragment>
          <InputNumber defaultValue={record.max_allowed} onChange={(v) => {this.setState({changedValue: v})}}/>
          <Button type="primary" onClick={() => {this.changeMaxAllowed(record.id)}}>Ok</Button>
          </React.Fragment>
        },
      },
      {
        title: 'V1 Users',
        dataIndex: 'v1_users',
        key: 'v1_users'
      },
      {
        title: 'Email',
        dataIndex: 'emails',
        key: 'emails'
      },
      {
        title: 'Completed DUT',
        dataIndex: 'completed_detailed_user_tasks',
        key: 'completed_detailed_user_tasks'
      },
      {
        title: 'Business DUT',
        dataIndex: 'actual_users_for_email_call',
        key: 'actual_users_for_email_call'
      },
      {
        title: 'Remaining DUT',
        dataIndex: 'not_completed_detailed_user_tasks',
        key: 'not_completed_detailed_user_tasks'
      },
      {
        title: 'Latest Date',
        key: 'latest_date',
        render: (text, record) => {
          return <Button type="primary" onClick={() => {this.latest_date(record.id)}}>Reset</Button>
        },
      },
      {
        title: 'PRIVATE',
        dataIndex: 'completed_detailed_user_private',
        key: 'completed_detailed_user_private'
      },


      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Completed so Far',
        dataIndex: 'completed_so_far',
        key: 'completed_so_far'
      },
      {
        title: 'Coins',
        dataIndex: 'coins',
        key: 'coins',
        render: d => {if (d) {return d} else {return -1}}
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => ConvertToReadableDateTime(text)
      },

      {
        title: 'Is Completed',
        dataIndex: 'is_completed',
        key: 'is_completed',
        render: d => {if (d) {return "True"} else {return "False"}}
      },

      // {
      //   title: 'Get Details',
      //   key: 'task_details',
      //   render: (text, record) => {
      //     return <Button type="primary" onClick={() => this.getSpecificTaskDetails(record.id)}>Details</Button>
      //   },
      // },



    ];
    console.log(this.state, 'Line 82 | Class: AllTasksWithDetails | Function: render: ')

    return <React.Fragment>
      <Divider />
      {this.state.isLoading ? <div className="center"><Spin /></div> : <React.Fragment />}
      <Button onClick={this.getTasksDetails}>Refresh</Button>
      <br />
      Email: <Input value={this.state.user} onChange={e => this.setState({user: e.target.value})} />
      Username: <Input value={this.state.username} onChange={e => this.setState({username: e.target.value})} />
      <Table
        // pagination={{ pageSize: 20}}
        columns={columns}
        rowKey={record => record.id}
        scroll={{ x: 'max-content' }}
        dataSource={this.state.tasks.filter(t =>  {
          if (this.state.username.length < 1) {
            return t.email.includes(this.state.user)
          }
          return t.email.includes(this.state.user) && t.ig_username && t.ig_username.includes(this.state.username)
        })}
      />

      <Modal
        visible={this.state.modalOpened}
        onOk={this.handleOk}
        onCancel={this.handleOk}
        title={"More Details"}
      >
        <strong>Completed</strong> Detailed Users: {this.state.details.completed_detailed_user_tasks}
        <br />
        <strong>Remaining</strong> Detailed Users: {this.state.details.not_completed_detailed_user_tasks}
        <br />
        <strong>Private</strong> Users: {this.state.details.completed_detailed_user_private}
        <br />
        <strong> Emails</strong>: {this.state.details.email_count}
        <br />
        <strong>V1 Users</strong>: {this.state.details.v1_users}
      </Modal>

    </React.Fragment>
  }

  handleOk = async e => {
    this.setState({
      modalOpened: false
    });
  };
}

export default AllTasksWithDetails