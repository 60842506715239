import React, {useEffect} from 'react'
import {Row, Col, Typography, Divider, Button} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import { Link } from 'react-router-dom';

const {Title} = Typography

const links = [
  {
    title: 'Profilebud',
    id: 2,
    link: '/profilebud'
  },
  {
    title: 'FAQ Service',
    id: 5,
    link: '/faq_service'
  },
  {
    title: 'Growpad',
    id: 6,
    link: '/growpad'
  },
  {
    title: 'Leadgen',
    id: 7,
    link: '/leadgen'
  },
  {
    title: 'Agency',
    id: 8,
    link: '/agency'
  }
]

const HomePage = ({init, setKey}) => {

  useEffect(()=>init(), [])

  return(
    <PlainContentWrapper>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 20}}>
          <ContentWrapper marginLess>
            <Title level={2} style={{fontWeight: 'medium'}}>
              Where to go
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            {links.map((item, index) => (
              <Button style={{marginBottom: '0.75rem'}} type="primary" block size="large" key={index}>
                <Link to={item.link} >
                  {item.title}
                </Link>
              </Button>
            ))}
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default HomePage