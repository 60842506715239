import React, {useState} from "react";
import {Checkbox, Form, Input, Button, Spin} from "antd";
import AddUserApi from "../../Common/ApiCall/AddUserApi";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddUser = () => {
  const [load, setLoad] = useState(false)

  const onFinish = values => {
    setLoad(true)
    AddUserApi(values.email, values.password, values.has_base_product)
      .then(r => {
        if(r.success) {
          SuccessNotif("Added Successfully")
        } else {
          BadNotif("", r)
        }
        setLoad(false)
      })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return(
    <div>
      <Form
        {...layout}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{required: true, message: 'Please input Email'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{required: true, message: 'Please input Password'}]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          {...tailLayout}
          name="has_base_product"
          initialValue={true}
        >
          <Checkbox>Base Product</Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout} >
          {load ? (
            <Button type="primary" >
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>

      </Form>
    </div>
  )
}

export default AddUser