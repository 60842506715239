import React from "react";
import {Button, Col, Divider, Row, Typography} from "antd";
import ContentWrapper from "../../Components/ContentWrapper";
import PlainContentWrapper from "../../Components/PlainContentWrapper";
import {GetRequest} from "../../Common/ApiCall/Requests";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";
import Box from "./Box";
import ProxyList from "./ProxyList";
import SubmitProxy from "./SubmitProxy";
import NotWorkingAccountsFix from "./NotWorkingAccountsFix";
import Statistics from "./Statistics";

const {Title} = Typography


class Leadgen extends React.Component {
  constructor(props) {
    super(props);
    this.props.init();
  }





  render() {
    return <React.Fragment>
      <PlainContentWrapper marginLess>
        <Row justify="center" gutter={24}>
          <Col lg={{span: 22}} md={{span: 24}}>
            <ContentWrapper marginLess>

              <Statistics />

              <NotWorkingAccountsFix />

              <ProxyList />

              <SubmitProxy />

            </ContentWrapper>
          </Col>
        </Row>
      </PlainContentWrapper>
    </React.Fragment>
  }
}

export default Leadgen