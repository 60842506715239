import React from "react";
import {Button, Card, Table} from "antd";
import ConvertToReadableDateTime from "../../Common/Utils/ConvertToReadableDateTime";
import * as copy from "copy-to-clipboard";
import SendNotification from "../../Common/Utils/SendNotification";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";


class ShowPaypalUserSubscription extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    const columns = [
      {
        title: 'IG ID',
        dataIndex: 'ig_id',
        key: 'ig_id'
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username'
      },
      {
        title: 'IG Email',
        dataIndex: 'ig_email',
        key: 'ig_email'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button onClick={() => {
            copy("https://app.growpad.io/paypal-to-stripe/"+record.ig_id);
            SendNotification(NotificationTypeEnum.Success, "Copied")
          }}>
            Copy
          </Button>)
      }
      ]
    return <React.Fragment>
      <Card style={{margin: "1%", padding: "1%", backgroundColor: "#f5f5f5"}}>
        Plan Name: {this.props.plan_name}<br />
        <strong>Expiry : {ConvertToReadableDateTime(this.props.expiry_date)}</strong><br />
        Growpad Signup Mail: {this.props.growpad_email}<br />
        Paypal Email: {this.props.paypal_email}<br />
        <strong>Paypal Name: {this.props.paypal_name}</strong><br />
        Paypal Status: {this.props.paypal_status}<br />
        Paypal Sub. ID: {this.props.paypal_subscription_id}<br />
        <Table
          pagination={{ pageSize: 3}}
          columns={columns}
          dataSource={this.props.ig_users} />
      </Card>
    </React.Fragment>
  }
}

export default ShowPaypalUserSubscription