import React, {useEffect, useState} from "react";
import {Form, Input, Avatar, Typography, DatePicker, Divider, Card, InputNumber, Tooltip, Radio, Button, Switch, Spin} from "antd";
import GetDetailedUserByEmail from "../../Common/ApiCall/GetDetailedUserByEmail";
import {CloseCircleOutlined, ReloadOutlined} from '@ant-design/icons'
import Loader from "../../Components/Loader";
import { QueryUserById, RemoveIgAccount, ChangePayedUserApi, ChangeVIPCalls, GetDetailedUserEmail, GetDetailedUserIgId, RemoveUserApi } from "../../Common/ApiCall/UserApi";
import SendNotification, { BadNotif, SuccessNotif } from "../../Common/Utils/SendNotification";
import { GrowpadChangePayedUserApi, GrowpadChangePasswordApi, GrowpadGetDetailedUserByEmail, GrowpadUpdateIgAccount, GrowpadChangeExiryTime, GrowpadGetDetailedUserIgId, GrowpadQueryUserById, GrowpadRemoveUserApi, GrowpadRemoveIgAccount } from "../../Common/ApiCall/GrowpadApi";
import moment from 'moment';
import GetIgDataFromUsername from "../../Common/ApiCall/GetIgDataFromUsername";
import ApiError from "../../Common/Models/ApiError";
import NotificationTypeEnum from "../../Common/Models/NotificationTypeEnum";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const {Meta} = Card
const {Title} = Typography

export const IgUserPanel = ({ig_id, back, visitUser}) => {
  const [load, setLoad] = useState(false)
  const [initloader, setInitLoader] = useState(true)
  const [data, setData] = useState({})

  function init() {
    GetDetailedUserIgId(ig_id)
      .then(r => {
        if(r.success) {
          setData(r.data)
          setInitLoader(false)
        }
      })
  }

  function RefreshData() {
    GrowpadGetDetailedUserIgId(ig_id)
      .then(r => {
        if(r.success) {
          setData(r.data)
        }
      })
  }

  function goToUser() {
    GrowpadQueryUserById(data.user_id)
      .then(r => {
        if(r.success) {
          visitUser(r.data.user.payment_email)
        } else {
          BadNotif('', r)
        }
      })
  }

  useEffect(()=>{
    init()
  }, [])

  if(initloader) {
    return <Loader />
  }

  return (
    <>
    <Title>
      <Tooltip title="Refresh User Data" >
        <Button shape="circle" onClick={RefreshData} style={{float: 'right', marginRight: '10px'}} >
          <ReloadOutlined
            style={{fontSize: '12px'}}
          />
        </Button>
      </Tooltip>
      <Divider />
    </Title>

    {
    load ? (
      <Title>
        <Spin />
      </Title>
    ) : null
    }

    <Form
      {...layout}
      hideRequiredMark
    >

      <Form.Item
        name="Email"
        label="Email"
        rules={[{required: true}]}
        initialValue={data.payment_email}
      >
        <Input
          disabled
        />
      </Form.Item>
      <Form.Item
        name="ig_id"
        label="Ig Id"
        rules={[{required: true}]}
        initialValue={data.ig_id}
      >
        <Input
          disabled
        />
      </Form.Item>

      <Form.Item
        name="username"
        label="Ig Username"
        rules={[{required: true}]}
        initialValue={data.username}
      >
        <Input
          disabled
        />
      </Form.Item>

      <Form.Item 
        {...tailLayout}
      >
        {
          data.user_id ? (
            <Button type="primary" onClick={goToUser} >
            View / Edit User
          </Button>
          ) : (
            <>
              No Account Associated
            </>
          )
        }
      </Form.Item>

    </Form>

    </>
  )
}

const UserPanel = ({user_id, back}) => {
  const [load, setLoad] = useState(false)
  const [query, setQuery] = useState('')
  const [mailForm] = Form.useForm()
  const [satanCallsForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [expiryTimeForm] = Form.useForm()
  const [oldPasswordForm] = Form.useForm()
  const [, forceUpdate] = useState()
  const [IGForm] = Form.useForm()
  const [user, setUser] = useState(null)
  const [ig_accounts, set_ig_accounts] = useState([])
  const [igAccount, setIgAccount] = useState(null)
  const [initloader, setInitLoader] = useState(true)
  const [username, setUsername] = useState('')
  const [subs, setSubs] = useState(null)

  function date(date_1) {
    const dateFormat = 'YYYY/MM/DD';
    let date = new Date(date_1)
    return moment(date, dateFormat)
  }

  function RefreshData() {
    setLoad(true)
    GrowpadQueryUserById(user_id)
      .then(r => {
        if(r.success) {
          setLoad(false)
          setUser(r.data.user)
          set_ig_accounts(r.data.ig_accounts)
          setInitLoader(false)
          setSubs(r.data.subscription_exists)
          oldPasswordForm.setFieldsValue({password: r.data.user.password})
          passwordForm.setFieldsValue({new_password: ''})
        }
      })
    /*GrowpadGetDetailedUserByEmail(user_email)
      .then(r => {
        setLoad(false)
        setUser(r.data.user)
        set_ig_accounts(r.data.ig_accounts)
        setInitLoader(false)
      })*/
  }

  function getUserData(email) {
    GetDetailedUserEmail(email)
      .then(r => {
        setUser(r.data.user)
        set_ig_accounts(r.data.ig_accounts)
        setInitLoader(false)
      })
  }

  function changePassword(values) {
    setLoad(true)
    GrowpadChangePasswordApi(user_id, values.new_password)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('Password Changed')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changeExpiryTime(values) {
    setLoad(true)
    GrowpadChangeExiryTime(user.id, values.expiry_time)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('Expiry Time Changed')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function changePayedUser(values) {
    setLoad(true)
    GrowpadChangePayedUserApi(user.id, values.is_payed)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('User Permission Changed')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  function deleteUserAccount() {
    setLoad(true)
    GrowpadRemoveUserApi(user_id)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('User Deleted')
          back()
        } else {
          BadNotif('', r)
        }
      })
  }

  function removeIgAccount(ig_id) {
    GrowpadRemoveIgAccount(user_id, ig_id)
    .then(r=>{
      if(r.success) {
        SuccessNotif('IG Account Removed')
        init()
      } else {
        BadNotif(r)
      }
    })
  }

  async function makeDetailedIDFromUsernames() {
    setLoad(true)
    if(username === '') {
      SendNotification(NotificationTypeEnum.Failure, "Enter Username to Continue")
      setLoad(false)
      return
    }
    let userData = await GetIgDataFromUsername(username)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      }
      setLoad(false)
      return
    }
    if(!userData.id) {
      SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      setLoad(false)
      return
    }
    setIgAccount(userData)
    addIgAccount(userData.username, userData.id)
  };

  function addIgAccount(username, ig_id) {
    GrowpadUpdateIgAccount(user.id, ig_id, username)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif('Ig Account Added')
          init()
        } else {
          BadNotif('', r)
        }
      })
  }

  async function makeDetailedIDFromUsername() {
    /*setLoad(true)
    let userData = await (query)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif("Instagram username does not exist");
      }
      setLoad(false)
      return
    }
    Add(user_email,userData.id, userData.username, userData.profile_pic)
      .then(r => {
        if(r.success) {
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoad(false)
        RefreshData()
      })
      */
  }

  function init() {
    RefreshData()
    forceUpdate({})
  }

  useEffect(()=>{
    init()
  }, [])

  if(initloader) {
    return <Loader />
  }

  return(
    <>
      <Title>
        <Tooltip title="Refresh User Data" >
          <Button shape="circle" onClick={RefreshData} style={{float: 'right', marginRight: '10px'}} >
            <ReloadOutlined
              style={{fontSize: '12px'}}
            />
          </Button>
        </Tooltip>
        <Divider />
      </Title>

{
  load ? (
    <Title>
      <Spin />
    </Title>
  ) : null
}

      <Form
        {...layout}
        form={mailForm}
        hideRequiredMark
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{required: true}]}
          initialValue={user.payment_email}
        >
          <Input
            disabled
            type="email"
            placeholder="Enter Email here to Change"
          />
        </Form.Item>
      </Form>

      <Form
      onValuesChange={changePayedUser}
        {...layout}
      >
        <Form.Item
          name="is_payed"
          label="Is Payed User"
        >
          <Switch
            checked={user.is_payed}
          />
        </Form.Item>
      </Form>

      <Form
        {...layout}
      >
        <Form.Item
          label="Subscription Exists"
        >
          {subs ? "True" : "False"}
        </Form.Item>
      </Form>

      <Form
        {...layout}
        onFinish={changeExpiryTime}
        form={expiryTimeForm}
      >
        <Form.Item
          rules={[{required: true}]}
          name="expiry_time"
          label="Expiry Date"
          initialValue={date(user.current_period_end)}
        >
          <DatePicker 
          />
        </Form.Item>
        <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!expiryTimeForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
      </Form>

      {/*user.is_payed ? (
        <Form
          form={satanCallsForm}
          onFinish={changeSatanCalls}
          hideRequiredMark
          {...layout}
        >
          <Form.Item
            label="Satan Calls Remaining"
            name="satan_calls_remaining"
            rules={[{required: true, type: 'number', min: 0}]}
            initialValue={user.satan_calls_remaining}
          >
            <InputNumber
            />
          </Form.Item>
          <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!satanCallsForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
      ): null*/}

      {/*user.is_payed ? (
        <Form
          hideRequiredMark
          {...layout}
        >
          <Form.Item
            label="Level 2 Calls Remaining"
            name="level_2_calls_remaining"
            initialValue={"UNLIMITED"}
            rules={[{required: true, type: 'number', min: 0}]}
          >
            <Input
              disabled
            />
          </Form.Item>
        </Form>
      ): null*/}

      <Form
        {...layout}
      >
        <Form.Item
          label="Instagram Accounts"
        >
          {/*ig_accounts.length < 3 ? (
                          <Input.Search
                          onPressEnter={makeDetailedIDFromUsernames}
                          onSearch={makeDetailedIDFromUsernames}
                          value={query}
                          placeholder="Enter Username"
                          size="large"
                          onChange={e=>setQuery(e.target.value)}
                        />
          ) : null*/}
          {ig_accounts.length > 0 ? (
            ig_accounts.map((item, index) => (
              <Card style={{ width: 300, marginTop: 16 }}>
                <Meta
                  avatar={
                    <Avatar
                      src={item.profile_pic}
                    />
                  }
                  title={item.username}
                />
                <CloseCircleOutlined
                  className="cursor-pointer"
                  onClick={()=>removeIgAccount(item.ig_id)}
                />
              </Card>
            ))
          ): (
            <div>
              No Associated Accounts
            </div>
          )}
        </Form.Item>
        {
          ig_accounts.length===0 ? (
            <Form.Item
            label="Add Account"
          >
                <Input 
                  onChange={(c)=>setUsername(c.target.value)}
                  placeholder="Enter Ig Username"
                />
                    <Button disabled={username.length===0} onClick={makeDetailedIDFromUsernames} >
                      Confirm                
                    </Button>
          </Form.Item>
          ) : null
        }
      </Form>

      <Form
        {...layout}
        form={oldPasswordForm}
      >
      <Form.Item
          name="password"
          label="Password"
          initialValue={user.password}
        >
          <Input 
            disabled
          />
        </Form.Item>
      </Form>

      <Form
        {...layout}
        hideRequiredMark
        form={passwordForm}
        onFinish={changePassword}
      >
        <Form.Item
          name="new_password"
          label="Change Password"
          rules={[{
            required: true,
            min: 8,
            max: 24
          }]}
        >
          <Input
            placeholder="Enter Password here to Change"
            maxLength={24}
            minLength={8}
          />
        </Form.Item>
        <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!passwordForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
      </Form>

      <Form
        {...layout}
        onFinish={deleteUserAccount}
      >
        <Form.Item
          {...tailLayout}
        >
          <Button type="primary" htmlType="submit" danger>
            Delete Account
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UserPanel