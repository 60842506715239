import React, {useEffect, useState} from "react";
import {Table} from "antd";
import GetPlans from "../../Common/ApiCall/InfluencerbitApiCalls/GetPlans";
import {BadNotif} from "../../Common/Utils/SendNotification";

const Plans = () => {
    const [plans, setPlans] = useState([])
    const [load, setLoad] = useState(true)

    const loading = () => setLoad(true)
    const loaded = () => setLoad(false)

    const getPlans = async () => {
        loading()
        const res = await GetPlans()
        if(res.success) {
            setPlans(res.data.plans)
        } else {
            BadNotif("", res)
        }
        loaded()
    }

    useEffect(() => {
        getPlans()
    }, [])

    const plansColumns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Product Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Product Id",
            dataIndex: "product_id",
            key: "product_id"
        },
        {
            title: "Days",
            dataIndex: "days",
            key: "days"
        },
        {
            title: "Days Description",
            dataIndex: "days_description",
            key: "days_description"
        },
        {
            title: "Monthly",
            dataIndex: "is_monthly",
            render: i => i ? "Yes" : "No",
            key: "is_monthly"
        },
        {
            title: "Price In Cents",
            dataIndex: "price_in_cents",
            key: "price_in_cents"
        },
        {
            title: "Price In USD",
            dataIndex: "price_in_cents",
            key: "price_in_usd",
            render: i => `$ ${Math.round(i/100, 2)}`
        },
        {
            title: "Stripe Price Id",
            dataIndex: "stripe_price_id",
            key: "stripe_price_id"
        }
    ]

    return (
        <>
            <Table
                dataSource={plans}
                loading={load}
                rwKey={"id"}
                columns={plansColumns}
                bordered
            />
        </>
    )
}

export default Plans