import { GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";
import GrowpadConstant from './GrowpadConstant'

export const SearchByIgUsername = async (query) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/search_user_by_username", 'growpad', true), {
      username: query,
      growpad_secret: GrowpadConstant.GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: '123445',
    success: true,
    errors: []
  }
}

export default SearchByIgUsername