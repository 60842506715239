const ApiConstants = {
    ibUrl: "https://dev.influencerbit.com",
    ibAuthKey: "b2YqEntCaBmNmfyvxerKIu3vsTZsBKHf",
    ibAdminPath: "/ib/api/admin/v1/",
    RunIbServer: true
}

export function getIbURL(url) {
    return ApiConstants.ibUrl + ApiConstants.ibAdminPath + url
}

export default ApiConstants