import { PostRequest, GetRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import {GenerateUrl, RunServer} from "../ApiUrl";

const GROWPAD_SECRET = 'AFE7DDFF45F5D96FA5AAF34C81C4A'

export const CreateIgUserApi = async (username, ig_id, expiry_date) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/create_ig_user_if_not_exists", 'growpad', true), {
      username, ig_id, expiry_date,
      growpad_secret: GROWPAD_SECRET
    })
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    data: {},
    success: true,
    errors: []
  }
}