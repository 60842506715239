import ApiError from "../Models/ApiError";

export const RunServer = true

export const ApiUrl = "https://api2.profilebud.com/insta/api/v5/admin"

export const DevUrl = "http://localhost:3000/insta/api/v5/admin"

export const GrowpadNewApiUrl = (false && process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api2.growpad.io') + '/growpad/api/admin/v2'

export const GrowpadApiUrl = (process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api2.growpad.io') + '/growpad/api/admin/v1'

export function GenerateUrl(path, link = 'profilebud', newApi = false) {
  if(link === 'growpad') {
    return newApi ? (GrowpadNewApiUrl+path) : (GrowpadApiUrl+path)
  }
  return(ApiUrl+path)
}

let message = "Something went wrong. Error: "

export function ConstructErrorMessage(ApiMessage) {
  return new ApiError(message+ApiMessage)
}