import React, { useState , useEffect} from 'react'
import {Button, Popconfirm, Input, Space, Table, Form, Typography, Switch, Breadcrumb} from 'antd'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';
import GetFAQSites from '../../Common/ApiCall/GetFAQSites';
import { ChangeFaqSiteNameApi, DeleteFaqSiteApi } from '../../Common/ApiCall/FAQApi';
import ShowFaqGroups from './ShowFaqGroups';
import Loader from '../../Components/Loader'
import AllFaqItems, {AddFaqItem} from './AllFaqItems';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
const tailLayout = {
    wrapperCol: {offset: 6, span: 14}
}

const {Title} = Typography

const AllFaqSites = () => {
    const [init, setInit] = useState(true)
    const [items, setItems] = useState([])
    const [faqs, setFaqs] = useState(false)
    const [editor, setEditor] = useState(false)
    const [group, setGroup] = useState(false)
    const [add_faq, set_add_faq] = useState(false)
    const [faq_site_id, set_faq_site_id] = useState(-1)
    const [faq_site, set_faq_site] = useState({})
    const [popVIsible, setPopVisible] = useState(false)
    const [popId, setPopId] = useState(null)

    function initial() {
        GetFAQSites()
            .then(r => {
                setItems(r.data)
                setInit(false)
            })
    }

    function refresh() {
        setInit(true)
        initial()
    }

    useEffect(() => {
        initial()
    }, [])

    function deleteSite(site_id) {
        DeleteFaqSiteApi(site_id)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Faq Site Deleted')
                    refresh()
                } else {
                    BadNotif('', r)
                }
            })
    }

    function makePopVis(id) {
        setPopId(id)
        setPopVisible(true)
    }

    const columns = [
        {
            title: 'Site Name',
            dataIndex: 'site_name',
            key: 'site_name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (item) => (
                <Space size="middle">
                    <Button onClick={()=>{console.log(item);setEditor(true);set_faq_site_id(item.id);set_faq_site(item)}} >
                        View / Edit
                    </Button>
                    <Popconfirm  
                        title="Are you sure you want to delete this Faq Site and its Items?"
                        okText="Yes"
                        cancelText="No"
                        visible={popVIsible && popId===item.id}
                        onConfirm={()=>{setPopVisible(false);deleteSite(item.id)}}
                        onCancel={()=>{setPopVisible(false)}}
                    >
                        <Button type="danger" onClick={()=>makePopVis(item.id)}>
                            Delete
                        </Button>
                    </Popconfirm>
                    <Button type="primary" onClick={()=>{setGroup(true);set_faq_site(item);set_faq_site_id(item.id)}} >
                        Groups
                    </Button>
                    <Button onClick={()=>{setFaqs(true);set_faq_site(item);set_faq_site_id(item.id)}}>
                        FAQs
                    </Button>
                    <Button type="ghost" onClick={()=>{set_add_faq(true);set_faq_site(item);set_faq_site_id(item.id)}} >
                        Add Faq
                    </Button>
                </Space>
            )
        }
    ]

    if(init) {
        return <Loader />
    }

    if(faqs) {
        return (
            <AllFaqItems 
                site={faq_site}
                group={null}
                back={()=>setFaqs(false)}
            />
        )
    }

    if(add_faq) {
        return(
            <AddFaqItem 
                site={faq_site}
                group={null}
                back={()=>{set_add_faq(false)}}
                refresh={()=>{}}
            />
        )
    }

    if(editor) {
        return(
            <>
            <Title level={2}>
                 FAQ Panel
                <Button className="menu-float-right" onClick={()=>setEditor(false)} >
                  Back to All
                </Button>
            </Title>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {faq_site.site_name}
                </Breadcrumb.Item>
            </Breadcrumb>
                <FAQSiteEditor
                    back={()=>{setEditor(false);refresh()}}
                    faq_site={faq_site}
                />
            </>
        )
    }

    if(group) {
        return(
            <ShowFaqGroups 
                back={()=>setGroup(false)}
                site = {faq_site}
            />
        )
    }

    return(
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    All Sites
                </Breadcrumb.Item>
            </Breadcrumb>
            <Table 
                dataSource={items}
                columns={columns}
            />
        </>
    )
}

const FAQSiteEditor = ({faq_site, back}) => {
    const [nameForm] = Form.useForm()
    const [, forceUpdate] = useState()

    useEffect(() => {
        forceUpdate({});
      }, []);

    function changeSiteName(values) {
        ChangeFaqSiteNameApi(faq_site.id, values.title)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Updated')
                    back()
                } else {
                    BadNotif('', r)
                }
            })
    }

    return(
        <>
        <Form
            {...formItemLayout}
            form={nameForm}
            onFinish={changeSiteName}
            initialValues={{
                title: faq_site.site_name
            }}
        >
            <Form.Item
                label="Title"
                name="title"
            >
                <Input 
                    placeholder="Title"
                />
            </Form.Item>
            <Form.Item 
                {...tailLayout}
                shouldUpdate={true}
                hidden={nameForm.isFieldsTouched(true)}
            >
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!nameForm.isFieldsTouched(true)}
                >
                    Save
                </Button>
                )}
            </Form.Item>
        </Form>
        </>
    )
}

export default AllFaqSites