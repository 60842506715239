import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Radio, Select, Space, Table, Typography} from "antd";
import GetCoupons from "../../Common/ApiCall/InfluencerbitApiCalls/GetCoupons";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import GetPlans from "../../Common/ApiCall/InfluencerbitApiCalls/GetPlans";
import SaveCoupon from "../../Common/ApiCall/InfluencerbitApiCalls/SaveCoupon";
import getCoupons from "../../Common/ApiCall/InfluencerbitApiCalls/GetCoupons";
import {CopyOutlined} from "@ant-design/icons";
import AddCouponApi from "../../Common/ApiCall/InfluencerbitApiCalls/AddCouponApi";

const {Text} = Typography

const Coupons = () => {
    const [coupons, setCoupons] = useState([])
    const [load, setLoad] = useState(true)
    const [plans, setPlans] = useState([])
    const [products, setProducts] = useState([])
    const [selectedCoupon, setSelectedCoupon] = useState(null)
    const [add, setAdd] = useState(false)

    const loading = () => setLoad(true)
    const loaded = () => setLoad(false)

    const getCoupons = async () => {
        loading()
        const res = await GetCoupons()
        if(res.success) {
            setCoupons(res.data.coupons)
        } else {
            BadNotif("", res)
        }
        loaded()
    }

    const getPlans = async () => {
        loading()
        const res = await GetPlans()
        if(res.success) {
            setPlans(res.data.plans)
            setProducts(res.data.products)
        } else {
            BadNotif("", res)
        }
        loaded()
    }

    useEffect(() => {
        getCoupons()
        getPlans()
    }, [])

    const couponColumns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Coupon Code",
            dataIndex: "code",
            key: "code",
            render: a => (
                <div>
                    {a}
                    &nbsp;&nbsp;
                    <Button
                        onClick={() => {navigator.clipboard.writeText(a)}}
                        style={{
                            float: 'right'
                        }}
                        icon={<CopyOutlined />}
                        title={"Copy"}
                    />
                </div>
            )
        },
        {
          title: "Valid",
          dataIndex: "is_valid",
          key: "is_vallid",
          render: i => i ? "YES" : "NO"
        },
        {
            title: "Trial Days",
            dataIndex: "trial_days",
            key: "trial_days"
        },
        {
            title: "Custom Plan",
            render: i => {
                const pl = plans.filter(a => a.id === i.price_id)[0]
                return (
                    i.price_id ? (
                        <>
                            <Button
                                onClick={() => setSelectedCoupon(i)}
                            >
                                {"Update"}
                            </Button>
                            <br />
                            {pl && <Text>
                                Plan Name: {pl.name}
                                <br/>
                                Plan Id: {pl.id}
                                <br />
                                New Price (Cents): {i.new_price_in_cents}
                            </Text>}
                        </>
                    ) : (
                        <Button
                            onClick={() => setSelectedCoupon(i)}
                        >
                            {"Connect"}
                        </Button>
                    )
                )
            }
        }
    ]

    return (
        <>
            <AddCoupon
                vis={add}
                plans={plans}
                refresh={getCoupons}
                cancel={() => setAdd(false)}
            />
            <EditCouponPlan
                coupon={selectedCoupon}
                plans={plans}
                products={products}
                cancel={() => setSelectedCoupon(null)}
                refresh={getCoupons}
                key={selectedCoupon?.id}
            />
            <Space
                direction={'vertical'}
                style={{
                    width: '100%'
                }}
            >
                <Button
                    onClick={() => setAdd(true)}
                >
                    Add Coupon
                </Button>
                <Table
                    dataSource={coupons}
                    columns={couponColumns}
                    rowKey={"id"}
                    pagination={{
                        defaultPageSize: 25
                    }}
                    bordered
                    loading={load}
                />
            </Space>
        </>
    )
}

const AddCoupon = (
    {
        plans,
        cancel,
        refresh,
        vis
    }
) => {
    const [ccf] = Form.useForm()
    const [l, setL] = useState(false)

    async function addCoupon(values) {
        setL(true)
        if(values.trial_days < 0) {
            BadNotif("Trial Days cannot be less than 0.");return;
        }
        const res = await AddCouponApi(
            values.price_id,
            values.new_price_in_cents,
            values.code,
            values.trial_days,
            values.is_valid
        )
        if(res.success) {
            SuccessNotif("Coupon Added")
        } else {
            BadNotif("", res)
        }
        refresh()
        setL(false)
    }

    return (
        <Modal
            visible={vis}
            centered
            footer={null}
            onCancel={() => cancel()}
            title={"Add Coupon"}
        >
            <Form
                onFinish={addCoupon}
                form={ccf}
                hideRequiredMark
            >
                <Form.Item
                    noStyle
                >
                    Coupon Code
                    <Form.Item
                        name={"code"}
                        required
                    >
                        <Input
                            placeholder={"Coupon Code Here"}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name={"price_id"}
                    required
                >
                    <Select
                        style={{
                            minWidth: 200
                        }}
                        placeholder={"Select A Plan"}
                    >
                        <Select.Option
                            value={"nil"}
                            key={"nil"}
                            title={"NULL"}
                        >
                            NULL
                        </Select.Option>
                        {plans.map((p) => {
                            const n = p.name + " " + p.days_description
                            return (
                                <Select.Option
                                    value={p.id}
                                    title={n}
                                    key={p.id}
                                >
                                    {n}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    New Price In Cents
                    <Form.Item
                        name={"new_price_in_cents"}
                        required
                    >
                        <Input
                            type={'number'}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    Trial Days
                    <Form.Item
                        name={"trial_days"}
                        required
                    >
                        <Input
                            type={'number'}
                            min={0}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    Valid
                    <Form.Item
                        name={"is_valid"}
                        required
                    >
                        <Radio.Group>
                            <Radio
                                value={true}
                            >
                                Yes
                            </Radio>
                            <Radio
                                value={false}
                            >
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    shouldUpdate
                >
                    {() => {
                        return (
                            <Space>
                                <Button
                                    htmlType={'submit'}
                                >
                                    Add
                                </Button>
                                <Button
                                    htmlType={'reset'}
                                >
                                    Reset
                                </Button>
                            </Space>
                        )
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
}

const EditCouponPlan = (
    {
        coupon,
        plans,
        products,
        cancel,
        refresh
    }
) => {
    const [cf] = Form.useForm()
    const [l, setL] = useState(false)

    async function saveCoupon(values) {
        setL(true)
        const res = await SaveCoupon(values.price_id, values.new_price_in_cents, coupon.id)
        if(res.success) {
            SuccessNotif("Coupon Updated")
        } else {
            BadNotif("", res)
        }
        refresh()
        setL(false)
    }

    if (!coupon) {
        return null
    }

    return (
        <Modal
            visible={true}
            centered
            footer={null}
            onCancel={() => cancel()}
        >
            <Text>
                Coupon: <b>{coupon.code}</b>
            </Text>
            <br /><br />
            <Form
                initialValues={{
                    price_id: coupon.price_id,
                    new_price_in_cents: coupon.new_price_in_cents
                }}
                onFinish={saveCoupon}
                form={cf}
            >
                <Form.Item
                    name={"price_id"}
                >
                    <Select
                        style={{
                            minWidth: 200
                        }}
                        placeholder={"Select A Plan"}
                    >
                        <Select.Option
                            value={"nil"}
                            key={"nil"}
                            title={"NULL"}
                        >
                            NULL
                        </Select.Option>
                        {plans.map((p) => {
                            const n = p.name + " " + p.days_description
                            return (
                                <Select.Option
                                    value={p.id}
                                    title={n}
                                    key={p.id}
                                >
                                    {n}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    New Price In Cents
                    <Form.Item
                        name={"new_price_in_cents"}
                    >
                        <Input
                            type={'number'}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    shouldUpdate
                >
                    {() => {
                        const c = cf.getFieldsValue()
                        const changed = c.new_price_in_cents != coupon.new_price_in_cents || c.price_id != coupon.price_id
                        return (
                            <Button
                                disabled={!changed}
                                htmlType={'submit'}
                            >
                                Save
                            </Button>
                        )
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Coupons