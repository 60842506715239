import {GetRequest, PostRequest} from "../Requests";
import ApiResponse from "../../Models/ApiResponse";
import ApiConstants, {getIbURL} from "./ApiConstants";
import ibAuthHeader from "./ibAuthHeader";

const AddCouponApi = async (price_id, new_price_in_cents, code, trial_days, is_valid) => {
    let apiResponse;
    if (ApiConstants.RunIbServer || process.env.NODE_ENV !== "development") {
        apiResponse = await PostRequest(getIbURL("coupon/add"), {
            price_id,
            new_price_in_cents,
            code,
            trial_days,
            is_valid
        }, ibAuthHeader())
    } else {
        apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }

    if (apiResponse.body) {
        return apiResponse.body;
    } else {
        return apiResponse.error
    }
};

function dummyPlans() {
    return {
        data: {},
        success: true,
        errors: []
    }
}

export default AddCouponApi
